import React from 'react'
import { NavBar } from '../../../components/navbar'
import firebase from '../../../config/firebase';
import { getCliente, getEstacionamento, getRevenda, getRevendaIdByPark, getUsuario, getUsuarioCompact, isAdministrador } from '../../../config/auth'
import NumberFormat from 'react-number-format';
import { Loading, EmptyContent, ComboBoxCollection, HelpButton } from '../../../components/common/commons'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { sendClickButton, sendError, sendLog, sendLogByUser } from '../../../shared/analytics'
import Moment from 'moment';
import CurrencyInput from '../../../components/currencyInput/CurrencyInput'
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/storage';
import './index.css'
import { registerMonthlyPayment } from '../../../store/collections/cashierWorker';
import { CashierSelector, OpenCashier } from '../../cashier/components';
import { getFormasPagamentosAtivasSemTags } from '../../../store/collections/formaPagamentoWorker';
import { getMonthlyByDocumentAndParkId, getMonthlyById, updateDueDate, updateMonthlyById, updateVencimentoFatura } from '../../../store/collections/mensalistaWorker';
import { getAllContractsByMonthlyId } from '../../../store/collections/monthlyContractWorker';
import { getAllChagesByMonthlyId, removeMonthlyChage, changePlanChage, changeClienteNomeChage, getAllPendingChagesByMonthlyId, addChage, getChargeById, updateChageById } from '../../../store/collections/monthlyChagesWorker';
import { addVehicle, deleteVehicleById, getVehiclesByMonthlyIdAndClientId, getVehicleByPlateAndClientId, getVeiculos, updateVehicleById, getVehiclesByMonthlyId } from '../../../store/collections/veiculosWorker';
import Tooltip from '@mui/material/Tooltip';
import { saveLastUpdate } from '../../../store/collections/lastUpdateWorker';
import * as XLSX from 'xlsx';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import { getTicketById, ticketsByMonthlyId, ticketsByPlatesAndClientId, updateTicket } from '../../../store/collections/ticketWorker';
import { TicketAnalyticsDialog } from '../../buscas/ticketSearch';
import { copyLinkAndShare, delay, documentMask, exportAndPrintPDF, goToNewWindow, isNullOrEmpty, isValidDocument, isValidatePlate, normalizedField, queryString, reloadWindow, reloadWithAlert, setPathWindow, takeIfIsNotNullOrEmpty, toCurrency, toastDismissLoading, toastError, toastLoading, toastSuccess, toastWarning } from '../../../shared/utils';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import '../../dashboard/index.css'
import { NewNoteWidget } from '../../nota-fiscal/novo/index'
import { addNewNote, getNewNoteByIdentifier } from '../../../store/collections/newNotesWorker';
import { getParameterByParkId } from '../../../store/collections/parameterWorker';
import { RegisterAccessCardModal } from './modal';
import { getAllAccessCardsByReferenceId } from '../../../store/collections/accessCardWorker';
import { AlertDanger, AlertSecondary, AlertSuccess, AlertWarning } from '../../../shared/alert-manager';
import { searchCEP } from '../../../shared/cepWorker';
import { fetchFunction } from '../../../shared/function-request';
import { WhatsAppMessageListBody } from '../../developer/messages';
import { UserPermissionButton } from '../../operation/permission';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getAllBranchesAndModels } from '../../../store/collections/branchesAndModelsWorker';
import { MonthlyChargeReportDocument } from '../../../shared/pdf/monthly-charge-report';
import { QRCodeSVG } from 'qrcode.react';
import { PrinterMonthlyChargeComponent } from '../../../shared/printer/printer-ticket.js';
import ReactToPrint from 'react-to-print'
import { getParkById } from '../../../store/collections/parkWorker.js';
import { F360SyncMonthlyPayments } from '../../../managers/f360Manager.js';

const initialState = {
    id: null,
    isEditing: false,
    isLoading: true,
    nome: '',
    estacionamentoId: getEstacionamento()?.id,
    clienteId: getCliente()?.id,
    currentUser: getUsuario(),
    documento: '',
    tipoDocumento: 'CNPJ',
    monthToGenerateChange: 1,
    email: '',
    celular: '',
    planoId: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    complemento: '',
    valorPlano: 0.0,
    days: [],
    migrationMonthlyType: "PAGOS_E_PENDENTES",
    changeDueDateModal: false,
    monthlDueDate: Moment(new Date()),
    contracts: [],
    plano: {
        dataFatura: Date(),
        diaVencimento: '',
        planoId: '',
        validadeContrato: Date(),
        valor: 0.0,
    },
    endereco: {}
};

class DetalhesMensalista extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState
        this.load()
    }

    reset = () => {
        this.setState({ ...initialState })
    }

    load = async () => {
        this.setState({ isLoading: true })
        const monthly = await getMonthlyById(this.props.match.params.id)
        if (monthly && getCliente().id === monthly.clienteId) {
            this.setState({ ...monthly })
            this.setState({ oldVagas: this.state.vagas })
            this.setState({ oldMonthlyCategory: this.state.monthlyCategory })
            this.setState({ ...monthly.endereco })
            this.setState({ habilitarPagamentoMensalistasOnline: monthly.habilitarPagamentoMensalistasOnline ?? true })
            const parameters = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
            this.setState({ parameters: parameters })
            this.setState({ habilitarCartaoParaAcesso: parameters.habilitarCartaoParaAcesso })
            this.setState({ habilitarPagamentoOnlineMensalista: parameters.habilitarPagamentoOnlineMensalista })
            const showCharges = queryString("showCharges")
            const chargeId = queryString("chargeId")
            if (showCharges || chargeId) {
                this.setState({ showModalChages: true })
            }
            this.setState({ monthlDueDate: Moment(monthly.plano?.dataFatura?.toDate() ?? new Date()) })
            this.setState({ isLoading: false })
            if (queryString("edit") === "true") {
                this.editMonthly()
            }
            if (monthly.estacionamentoId) {
                const park = await getParkById({ id: monthly.estacionamentoId })
                this.setState({ park })
            }
        } else {
            toastWarning('Mensalista não encontrado')
            window.location.href = '/cadastrar/mensalista'
        }
    }

    editMonthly = () => {
        var days = []
        for (var i = 1; i < 32; i++) {
            days.push(i)
        }
        this.setState({ days: days })
        this.setState({ isEditing: true })
    }

    verifyUpdateChanges = async () => {
        if (this.state.vagas == this.state.oldVagas) {
            this.saveMonthly()
        } else {
            this.setState({ isShowingMonthlyChangesUpdate: true })
        }
    }

    saveMonthly = async () => {
        const document = documentMask(this.state.documento)
        if (!isNullOrEmpty(document)) {
            if (!isValidDocument(document)) {
                toastWarning("Informe um documento válido.")
                return
            }
            const result = await getMonthlyByDocumentAndParkId({ document: document, estacionamentoId: this.state.estacionamentoId })
            if (result && result.id !== this.state.id) {
                toastWarning(`O mensalista ${result.nome} já esta utilizando este documento.`)
            }
        }
        toastLoading("Atualizando mensalista...")
        const data = {
            nome: this.state.nome,
            documento: document,
            tipoDocumento: this.state.tipoDocumento,
            email: this.state.email,
            celular: this.state.celular,
            telefone: this.state.telefone,
            status: this.state.status,
            monthlyCategory: this.state.monthlyCategory ? this.state.monthlyCategory : null,
            vagas: isNaN(parseInt(this.state.vagas)) ? 1 : parseInt(this.state.vagas),
            endereco: {
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                complemento: this.state.complemento
            },
            alterAt: new Date(),
            alterBy: this.state.userEmail,
            observacoes: this.state.observacoes
        }
        await this.verifyAddCategoryToChanges()
        await updateMonthlyById({ id: this.props.match.params.id, data: data })
        await changeClienteNomeChage({ monthlyId: this.props.match.params.id, name: this.state.nome })
        await saveLastUpdate()
        const message = `Editou os dados do mensalista ${this.state.nome}.`
        sendLogByUser("Detalhes Mensalistas", message, true, data)
        setPathWindow(`/visualizar/mensalista/${this.props.match.params.id}`)
        toastDismissLoading()
        reloadWithAlert("Mensalista atualizado com sucesso!")
    }

    updateMonthlyChanges = async (updateMonthly) => {
        this.setState({ isShowingMonthlyChangesUpdate: false })
        toastLoading("Atualizando cobranças...")
        if (updateMonthly) {
            const pendingCharges = await getAllPendingChagesByMonthlyId(this.state.id)
            for (const charge of pendingCharges) {
                charge.netValue = parseFloat((this.state.plano.valor * this.state.vagas))
                await updateChageById({ id: charge.id, data: charge })
            }
            sendLogByUser("Cobranças", `Editou as vagas atualizadas de ${this.state.oldVagas} para ${this.state.vagas}`, this.state.id)
            await saveLastUpdate()
            toastDismissLoading()
            toastSuccess("Cobranças atualizadas com sucesso!")
        }
    }

    verifyAddCategoryToChanges = async () => {
        if (this.state.monthlyCategory == this.state.oldMonthlyCategory) {
            return
        }
        if (this.state.monthlyCategory != this.state.oldMonthlyCategory && isNullOrEmpty(this.state.oldMonthlyCategory)) {
            this.addCategoryToChanges(true)
        } else {
            this.addCategoryToChanges(false)
        }
    }

    addCategoryToChanges = async (addCategoryToAllChanges) => {
        this.setState({ isShowingMonthlyChangesUpdateCategory: false })
        toastLoading("Adicionando categoria ao mensalista...")
        var changes = []
        if (addCategoryToAllChanges == true) {
            changes = await getAllChagesByMonthlyId(this.state.id)
        } else {
            changes = await getAllPendingChagesByMonthlyId(this.state.id)
        }
        for (const change of changes) {
            change.monthlyCategory = this.state.monthlyCategory
            await updateChageById({ id: change.id, data: change })
        }
        sendLogByUser("Mensalista", `Adicionou a categoria ${this.state.monthlyCategory}`, this.state.id)
        await saveLastUpdate()
        toastDismissLoading()
        toastSuccess("Categorias adicionadas com sucesso")
        return
    }

    fetchCEP = async (value) => {
        this.setState({ cep: value })
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ cep: result.cep })
            this.setState({ logradouro: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ estado: result.uf })
        }
    }

    handleClosePlanModal = () => {
        this.setState({ openPlan: false })
    }

    handleChangeDueDateModal = () => {
        this.setState({ changeDueDateModal: false })
    }

    handleMonthlyDueDate = (newValue) => {
        this.setState({ monthlDueDate: newValue })
    }

    handleConfirmChangeDueDate = async () => {
        var confirm = window.confirm(`Deseja mesmo alterar a data de vencimento e cobranças pendentes deste mensalista?`);
        if (confirm) {
            var allChanges = await getAllPendingChagesByMonthlyId(this.state.id)
            for (let index = 0; index < allChanges.length; index++) {
                var change = allChanges[index]
                const dueCalendar = Moment(this.state.monthlDueDate.toDate()).add("months", index);
                change.changeDate = dueCalendar.toDate()
                await updateChageById({ id: change.id, data: change })
            }
            await updateDueDate({ mensalistaId: this.state.id, plano: this.state.plano, dueDate: this.state.monthlDueDate.toDate() })
            reloadWithAlert("Data de vencimento alterada com sucesso!")
        }
    }

    handleConfirmNewPlan = async () => {
        const confirm = window.confirm(`Deseja mesmo alterar o plano do mensalista?\n\nTodas as cobranças pendentes terão o novo valor do plano.`);
        if (confirm) {
            this.setState({ openPlan: false })
            const plano = {
                horarioFim: this.state.horarioFim,
                horarioInicio: this.state.horarioInicio,
                planoId: this.state.planoId,
                nome: this.state.planoNome,
                valor: this.state.planoValor,
                segunda: this.state.segunda,
                terca: this.state.terca,
                quarta: this.state.quarta,
                quinta: this.state.quinta,
                sexta: this.state.sexta,
                sabado: this.state.sabado,
                domingo: this.state.domingo,
                diaVencimento: this.state.plano?.diaVencimento ?? "1"
            }
            await firebase.firestore().collection('mensalistas').doc(this.props.match.params.id).set({
                plano: plano
            }, { merge: true })
            await changePlanChage({ monthlyId: this.props.match.params.id, plano: plano })
            reloadWithAlert("Plano do mensalista atualizado com sucesso!")
        }
    }

    openPlanModalAndLoad = () => {
        if (this.state.status === "inativo") {
            toastWarning("Não é possível alterar o plano para um mensalista inativo.")
            return
        }
        this.setState({ openPlan: true })
    }

    openAccessCardOpenModal = () => {
        this.setState({ isAccessCardOpenModal: true })
    }

    closeAccessCardOpenModal = () => {
        this.loadAccessCardsIfNeed()
        this.setState({ isAccessCardOpenModal: false })
    }

    onCardUpdated = () => {
        this.setState({ random: Math.random() })
    }

    loadAccessCardsIfNeed = async () => {
        const allAccessCards = await getAllAccessCardsByReferenceId({ referenceId: getCliente()?.id, estacionamentoId: getEstacionamento()?.id })
        this.setState({ allAccessCards: allAccessCards })
    }

    openChangeDueDate = () => {
        if (this.state.status === "inativo") {
            toastWarning("Não é possível alterar a data de vencimento para um mensalista inativo.")
            return
        }
        this.setState({ changeDueDateModal: true })
    }

    showAccessHistory = async () => {
        toastLoading("Buscando histórico de acessos...")
        const tickets = await ticketsByMonthlyId({ monthlyId: this.props.match.params.id })
        toastDismissLoading()
        this.setState({ tickets: tickets })
        this.setState({ showModalAccessHistory: true })
    }

    showMigrateMonthly = () => {
        this.setState({ migrateMonthlyModal: true })
    }

    showContractsMonthly = async () => {
        toastLoading("Buscando contratos...")
        const contracts = await getAllContractsByMonthlyId({ id: this.props.match.params.id })
        this.setState({ contracts: contracts })
        toastDismissLoading()
        this.setState({ contractMonthlyModal: true })
    }

    showContractDetail = (item) => {
        this.setState({ contractDetail: item })
        this.setState({ contractDetailModal: true })
    }

    hideMigrateMonthly = () => {
        this.setState({ migrateMonthlyModal: false })
    }

    onMigrateMonthlyParkId = (item) => {
        this.setState({ parkToMigrate: item })
    }

    confirmMigration = async () => {
        const alert = window.confirm(`Deseja mesmo migrar o mensalista ${this.state.nome} para o estacionamento ${this.state.parkToMigrate.nomeInterno ?? this.state.parkToMigrate.nome}?`)
        if (alert) {
            const migrationMonthlyType = this.state.migrationMonthlyType
            const parkToMigrate = this.state.parkToMigrate
            const mensalista = await getMonthlyById(this.props.match.params.id)
            mensalista.estacionamentoId = parkToMigrate.id
            await updateMonthlyById({ id: mensalista.id, data: mensalista })
            var chages = []
            if (migrationMonthlyType === "PAGOS_E_PENDENTES") {
                chages = await getAllChagesByMonthlyId(mensalista.id)
            } else {
                chages = await getAllPendingChagesByMonthlyId(mensalista.id)
            }
            for (const chage of chages) {
                chage.estacionamento.nome = parkToMigrate.nome
                chage.estacionamento.id = parkToMigrate.id
                chage.estacionamentoId = parkToMigrate.id
                await updateChageById({ id: chage.id, data: chage })
            }
            var vehicles = await getVehiclesByMonthlyId({ monthlyId: mensalista.id })
            for (const vehicle of vehicles) {
                vehicle.estacionamentoId = parkToMigrate.id
                await updateVehicleById({ id: vehicle.id, data: vehicle })
            }
            reloadWithAlert("Mensalista migrado com sucesso!")
        }
    }

    updateValorPlano = (event, maskedvalue, floatvalue) => {
        this.setState({ valorPlano: floatvalue })
    }

    onPlanoSelected = (item) => {
        this.setState({ planoNome: item.nome })
        this.setState({ planoId: item.id })
        this.setState({ planoValor: item.valorMensal })
        this.setState({ horarioFim: item.horarioFim })
        this.setState({ horarioInicio: item.horarioInicio })
        this.setState({ segunda: item.segunda })
        this.setState({ terca: item.terca })
        this.setState({ quarta: item.quarta })
        this.setState({ quinta: item.quinta })
        this.setState({ sexta: item.sexta })
        this.setState({ sabado: item.sabado })
        this.setState({ domingo: item.domingo })
    }

    openPaymentHistory = async () => {
        this.setState({ showModalChages: true })
    }

    closePaymentHistory = () => {
        this.setState({ showModalChages: false })
    }

    openModalGenerateChange = async () => {
        if (this.state.status === "inativo") {
            toastWarning("Não é possível gerar cobranças para um mensalista inativo.")
            return
        }
        this.setState({ isOpenModalGenerateChange: true })
        var changes = await getAllChagesByMonthlyId(this.props.match.params.id)
        const items = changes.sort((a, b) => a.changeDate?.toDate() - b.changeDate?.toDate())
        const item = items.map(e => e.changeDate?.toDate())[items.length - 1]
        this.setState({ lastCharge: item })
        sendClickButton("Mensalistas", "Abriu modal de geração de cobranças do mensalista.")
    }

    closeModalGenerateChange = async () => {
        this.setState({ isOpenModalGenerateChange: false })
    }

    confirmGenerateChanges = async () => {
        toastLoading("Gerando cobranças...")
        const monthly = await getMonthlyById(this.props.match.params.id)
        const plano = this.state.plano
        const park = getEstacionamento()
        const monthToGenerateChange = parseInt(this.state.monthToGenerateChange)
        const lastCharge = this.state.lastCharge
        for (let index = 0; index < monthToGenerateChange; index++) {
            const calendar = Moment(lastCharge).add((index + 1), 'M')
            const change = {
                type: "admin-added",
                clienteId: this.state.clienteId,
                clienteNome: monthly.nome,
                plan: plano,
                estacionamento: {
                    nome: park.nome
                },
                estacionamentoId: park.id,
                monthlyId: monthly.id,
                createBy: this.state.currentUser?.email ?? "",
                netValue: parseFloat((monthly.vagas ?? 1) * (parseFloat(plano.valor ?? plano.valorMensal)).toFixed(2)),
                changeDate: calendar.toDate(),
                status: "pending"
            }
            await addChage({ data: change })
        }
        await saveLastUpdate()
        toastDismissLoading()
        reloadWithAlert("Novas cobranças adicionadas com sucesso!")
    }

    onCloseAnalyticsDialog = () => {
        this.setState({ showModalAccessHistory: false })
    }

    showProfile = () => {
        const revenda = getRevenda()
        const url = `${revenda.siteTicket}/mensalista/cobrancas/?id=${this.state.clienteId}&m=${this.state.id}`
        goToNewWindow(url)
        const usuario = getUsuarioCompact()
        const message = `Tocou em visualizar perfil do mensalista ${this.state.nome}.`
        sendLogByUser("Detalhes Mensalistas", message)
    }

    onChangeHabilitarPagamentoMensalistasOnline = async (e) => {
        const habilitarPagamentoMensalistasOnline = e.target.checked
        toastLoading("Salvando...")
        this.setState({ habilitarPagamentoMensalistasOnline: habilitarPagamentoMensalistasOnline })
        const mensalistaId = this.state.id
        const data = {
            habilitarPagamentoMensalistasOnline: habilitarPagamentoMensalistasOnline
        }
        await updateMonthlyById({ id: mensalistaId, data: data })
        toastDismissLoading()
        if (habilitarPagamentoMensalistasOnline) {
            sendLogByUser("Detalhes Mensalistas", `Habilitou cobrança online para o mensalista ${this.state.nome}`, {
                mensalistaId: this.state.id,
            })
        } else {
            sendLogByUser("Detalhes Mensalistas", `Desabilitou cobrança online para o mensalista ${this.state.nome}`, {
                mensalistaId: this.state.id,
            })
        }
        if (this.state.status === "inativo" && habilitarPagamentoMensalistasOnline) {
            toastWarning("Nenhuma cobrança será enviada para mensalistas inativos.")
        } else {
            toastSuccess("Salvo com sucesso!")
        }
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Detalhes do mensalista
                                    <div className='btn-group'>
                                        {
                                            this.state.isEditing ?
                                                <>
                                                    <button onClick={(e) => this.setState({ isEditing: false })} className="btn btn-sm btn-secondary">
                                                        <i className="fas fa-edit mx-2" />
                                                        Cancelar
                                                    </button>
                                                    <button onClick={(e) => this.verifyUpdateChanges()} className="btn btn-sm btn-success">
                                                        <i className="fas fa-save mx-2" />
                                                        Salvar alterações
                                                    </button>
                                                </> :
                                                <>
                                                    <UserPermissionButton className="btn btn-sm btn-secondary" permission="MONTHLY_EDIT" action={this.editMonthly}>
                                                        <i className="fas fa-edit mx-2" />
                                                        Editar dados
                                                    </UserPermissionButton>
                                                    <button onClick={(e) => this.showProfile()} className="btn btn-sm btn-success">
                                                        <i className="fas fa-external-link-square-alt mx-2" />
                                                        Visualizar perfil
                                                    </button>
                                                </>
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.isEditing ?
                                        <div className='card-body'>
                                            <div className='row'>
                                                <div className="col-lg-3">
                                                    <label>Status</label>
                                                    <select className="form-select" value={this.state.status} onChange={e => this.setState({ status: e.target.value })} >
                                                        <option value='ativo'>Ativo</option>
                                                        <option value='inativo'>Inativo</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-9">
                                                    <label>Nome</label>
                                                    <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Vagas</label>
                                                    {
                                                        <select className="form-select" value={this.state.vagas} onChange={e => this.setState({ vagas: e.target.value })} >
                                                            <option value='1'>1</option>
                                                            {
                                                                this.state.days.filter(e => e !== 1).map(day => <option key={day} value={day}>{day}</option>)
                                                            }
                                                        </select>
                                                    }
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Categoria</label>
                                                    {
                                                        <select className="form-select" value={this.state.monthlyCategory} onChange={e => this.setState({ monthlyCategory: e.target.value === "" ? null : e.target.value })} >
                                                            <option value="">Nenhuma</option>
                                                            <option value="privado">Privado</option>
                                                            <option value="compartilhado">Compartilhado</option>
                                                            <option value="terceiros">Terceiros</option>
                                                        </select>
                                                    }
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Tipo documento</label>
                                                    <div className="input-group">
                                                        <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} >
                                                            <option value='CPF'>CPF</option>
                                                            <option value='CNPJ'>CNPJ</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Número documento</label>
                                                    <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>WhatsApp/Celular</label>
                                                    <NumberFormat className="form-control" value={this.state.celular} mask='_' onChange={(e) => this.setState({ celular: e.target.value })} format="(##)#####-####" />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Telefone/Comercial</label>
                                                    <NumberFormat className="form-control" value={this.state.telefone} mask='_' onChange={(e) => this.setState({ telefone: e.target.value })} format="(##)#####-####" />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>E-mail</label>
                                                    <input type="text" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Observações</label>
                                                    <textarea className="form-control" rows="3" onChange={(e) => this.setState({ observacoes: e.target.value })} value={this.state.observacoes}></textarea>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <h5 className='my-3'>Endereço</h5>
                                            </div>
                                            <div className='row'>
                                                <div className="col-lg-3">
                                                    <label>CEP</label>
                                                    <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Logradouro</label>
                                                    <input type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} className="form-control" />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Número</label>
                                                    <input type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} className="form-control" />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-lg-6">
                                                    <label>Bairro</label>
                                                    <input type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} className="form-control" />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Cidade</label>
                                                    <input type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} className="form-control" />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Estado</label>
                                                    <input type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} className="form-control" />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Complemento</label>
                                                    <input type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} className="form-control" />
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="card-body">
                                            {
                                                this.state.isLoading ? <Loading /> :
                                                    <>
                                                        <div className='row'>
                                                            {
                                                                this.state.park &&
                                                                <AlertSecondary tag="Estabelecimento">
                                                                    {takeIfIsNotNullOrEmpty(this.state.park?.nomeInterno) ?? this.state.park?.nome}<br />
                                                                    {this.state.park?.documento}
                                                                </AlertSecondary>
                                                            }
                                                            <div className="col-lg-12">
                                                                {
                                                                    this.state.status === "inativo" ?
                                                                        <div className='badge text-white text-bg-danger'>Inativo</div>
                                                                        :
                                                                        <div className='badge text-white text-bg-success'>Ativo</div>
                                                                }
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <strong>Nome: </strong> {this.state.nome}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <strong>Vagas: </strong> {this.state.vagas ?? 1}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <strong>Documento: </strong> {isNullOrEmpty(this.state.documento) ? "Não informado" : this.state.documento}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                {
                                                                    isNullOrEmpty(this.state.celular) ? <>
                                                                        <strong>Celular: </strong> Não informado
                                                                    </> : <>
                                                                        <strong>Celular: </strong> {isNullOrEmpty(this.state.celular) ? "Não informado" : this.state.celular}
                                                                        <Tooltip title="Clique para abrir o WhatsApp" placement="top">
                                                                            <a href={`https://wa.me/+55${normalizedField(this.state.celular)}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-success mx-2">
                                                                                <i className="fa-brands fa-whatsapp" />
                                                                            </a>
                                                                        </Tooltip>
                                                                    </>
                                                                }
                                                            </div>
                                                            {
                                                                this.state.monthlyCategory &&
                                                                <div className="col-lg-12">
                                                                    <strong>Categoria: </strong> <span className='text-capitalize'>{this.state.monthlyCategory}</span>
                                                                </div>
                                                            }
                                                            {
                                                                isNullOrEmpty(this.state.celular) && this.state.habilitarPagamentoOnlineMensalista &&
                                                                <div className='mt-2'>
                                                                    <AlertDanger message="Este cliente não irá receber as cobranças via WhatsApp, é preciso cadastrar o número de celular dele.">
                                                                        <div className='mt-2'>
                                                                            <UserPermissionButton className="btn btn-sm btn-danger" permission="MONTHLY_EDIT" action={this.editMonthly}>
                                                                                <i className="fas fa-edit mx-2" />
                                                                                Editar dados
                                                                            </UserPermissionButton>
                                                                        </div>
                                                                    </AlertDanger>
                                                                </div>
                                                            }
                                                            <div className="col-lg-12">
                                                                <strong>E-mail: </strong> {isNullOrEmpty(this.state.email) ? "Não informado" : this.state.email}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <strong>Observações: </strong> {isNullOrEmpty(this.state.observacoes) ? "Não informado" : this.state.observacoes}
                                                            </div>
                                                        </div>
                                                        <div className='my-3 row'>
                                                            {
                                                                this.state.plano ? <>
                                                                    <div className="col-lg-12">
                                                                        <h5>
                                                                            Total - {toCurrency((this.state.vagas ?? 1) * (this.state.plano.valor ?? 0))}
                                                                        </h5>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>Valor por vaga: </strong> {toCurrency(this.state.plano.valor)}
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>Nome: </strong> {this.state.plano.nome}
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>Horário inicio: </strong> {this.state.plano.horarioInicio}
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>Horário fim: </strong> {this.state.plano.horarioFim}
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>Vencimento: </strong>
                                                                        {
                                                                            this.state.plano.dataFatura ?
                                                                                <span>{Moment(this.state.plano.dataFatura.seconds * 1000).format("DD/MM/YYYY")}</span> :
                                                                                <span>Sem cobranças pendentes</span>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        this.state.plano?.validadeContrato &&
                                                                        <div className="col-lg-12">
                                                                            <strong>Vigência contrato: </strong>
                                                                            {Moment(this.state.plano.validadeContrato.seconds * 1000).format("DD/MM/YYYY")}
                                                                        </div>
                                                                    }
                                                                </> : <div>
                                                                    <h4>Sem plano associado</h4>
                                                                    <button className='btn btn-success w-25' onClick={this.openPlanModalAndLoad}>
                                                                        <i className="fas fa-exchange mx-2" />
                                                                        Associar plano
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            this.state.endereco === null || this.state.endereco?.logradouro === undefined || this.state.endereco?.logradouro === '' ?
                                                                <>
                                                                    <h4 className='my-3'>Endereço não informado</h4>
                                                                </> :
                                                                <div className='my-3 row'>
                                                                    <div className="col-lg-12">
                                                                        <strong>Endereço: </strong> {this.state.endereco.logradouro}, {this.state.endereco.numero} - {this.state.endereco.cidade}
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>CEP: </strong> {this.state.endereco.cep}
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>Bairro: </strong> {this.state.endereco.bairro}
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>Estado: </strong> {this.state.endereco.estado}
                                                                    </div>
                                                                    {
                                                                        (this.state.endereco.complemento !== null && this.state.endereco.complemento !== "") &&
                                                                        <div className="col-lg-12">
                                                                            <strong>Complemento: </strong> {this.state.endereco.complemento}
                                                                        </div>
                                                                    }
                                                                </div>
                                                        }
                                                        <div className='mt-2'>
                                                            <AlertSuccess tag="Cobrança de mensalistas">
                                                                <div className="form-check form-switch mt-2">
                                                                    <input className="form-check-input" id="habilitarPagamentoMensalistasOnline" checked={this.state.habilitarPagamentoMensalistasOnline} onChange={this.onChangeHabilitarPagamentoMensalistasOnline} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="habilitarPagamentoMensalistasOnline">
                                                                        Habilitar envio de cobrança para este cliente.
                                                                    </label>
                                                                </div>
                                                            </AlertSuccess>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                }
                                {
                                    !this.state.isLoading &&
                                    <div className="card-footer">
                                        <div className='row'>
                                            <div className='btn-group'>
                                                <button className='btn btn-success w-25' onClick={this.openPaymentHistory}>
                                                    <i className="fas fa-sack-dollar" /> <br />
                                                    <div className='text-center'>Cobranças</div>
                                                </button>
                                                {
                                                    this.state.habilitarCartaoParaAcesso &&
                                                    <button className='btn btn-danger w-25' onClick={this.openAccessCardOpenModal}>
                                                        <i className="fas fa-id-card" /> <br />
                                                        <div className='text-center'>Cartões de acesso</div>
                                                    </button>
                                                }
                                                {
                                                    this.state.plano &&
                                                    <UserPermissionButton className="btn btn-secondary w-25" action={this.openPlanModalAndLoad} permission="MONTHLY_CHANGE_PLAN">
                                                        <i className="fas fa-exchange" /> <br />
                                                        <div className='text-center'>Alterar plano</div>
                                                    </UserPermissionButton>
                                                }
                                                <UserPermissionButton className="btn btn-info text-white w-25" action={this.openChangeDueDate} permission="MONTHLY_CHANGE_DUE_DATE">
                                                    <i className="fas fa-calendar" /> <br />
                                                    <div className='text-center'>Alterar vencimento</div>
                                                </UserPermissionButton>
                                                <button className='btn btn-warning text-white w-25' onClick={this.showAccessHistory}>
                                                    <QueryStatsIcon /> <br />
                                                    <div className='text-center'>Histórico de acessos</div>
                                                </button>
                                            </div>
                                            <div className='my-3'>
                                                <h5>Administração</h5>
                                                <div className='btn-group'>
                                                    <button className='btn btn-danger w-25' onClick={this.showMigrateMonthly}>
                                                        <i className="fas fa-people-carry" /> <br />
                                                        <div className='text-center'>Migrar mensalista</div>
                                                    </button>
                                                    <UserPermissionButton
                                                        permission="MONTHLY_CHARGE_ADD"
                                                        className="btn btn-danger w-25"
                                                        action={this.openModalGenerateChange}>
                                                        <Tooltip title="Registrar pagamento" placement="top">
                                                            <>
                                                                <i className="fas fa-plus" /> <br />
                                                                <div className='text-center'>Gerar cobranças</div>
                                                            </>
                                                        </Tooltip>
                                                    </UserPermissionButton>
                                                    <button className='btn btn-danger w-25' onClick={this.showContractsMonthly}>
                                                        <i class="fa-solid fa-file-signature" /> <br />
                                                        <div className='text-center'>Contratos</div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ListarVeiculos key={this.state.random} clienteId={this.props.match.params.id} clienteNome={this.state.nome} />
                        </div>
                    </div>
                    {
                        this.state.isAccessCardOpenModal && <RegisterAccessCardModal onClose={this.closeAccessCardOpenModal} onCardUpdated={this.onCardUpdated} referenceId={this.props.match.params.id} type="monthly" />
                    }
                    <Dialog onClose={this.hideMigrateMonthly} maxWidth={'sm'} open={this.state.migrateMonthlyModal}>
                        <MuiDialogTitle className='text-center'>
                            Migrar mensalista
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <ComboBoxCollection
                                        title='Migrar para o estacionamento:'
                                        collection='estacionamentos'
                                        parentField='clienteId'
                                        parentValue={this.state.clienteId}
                                        onSelected={this.onMigrateMonthlyParkId} />
                                </div>
                                <div className='w-100'>
                                    <label>Pagamentos</label>
                                    <select className='form-select' value={this.state.migrationMonthlyType} onChange={(e) => this.setState({ migrationMonthlyType: e.target.value })}>
                                        <option value="PAGOS_E_PENDENTES">Migrar pagos e pendentes</option>
                                        <option value="PENDENTES">Migrar apenas pendentes</option>
                                    </select>
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="btn-group m-3">
                                <button onClick={this.hideMigrateMonthly} className='btn btn-secondary'>Fechar</button>
                                <button onClick={this.confirmMigration} className='btn btn-danger'>Confirmar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog onClose={this.handleChangeDueDateModal} maxWidth={'xs'} fullWidth open={this.state.changeDueDateModal}>
                        <MuiDialogTitle className='text-center'>
                            Alterar data de vencimento
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className='row'>
                                <AlertDanger message="Alterando a data de vencimento deste mensalista, todas as cobranças pendentes terão esta nova data de vencimento +1 mês cada." />
                                <div className='col-lg-12'>
                                    <MobileDatePicker
                                        label="Data de vencimento"
                                        inputFormat="DD/MM/yyyy"
                                        value={this.state.monthlDueDate}
                                        onChange={this.handleMonthlyDueDate}
                                        renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                    />
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="btn-group m-3">
                                <button onClick={this.handleChangeDueDateModal} className='btn btn-secondary'>Fechar</button>
                                <button onClick={this.handleConfirmChangeDueDate} className='btn btn-primary'>Confirmar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog onClose={this.handleClosePlanModal} maxWidth={'xs'} open={this.state.openPlan}>
                        <MuiDialogTitle className='text-center'>
                            Alterar plano
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className='row'>
                                <AlertDanger message="Alterando o plano do mensalista agora, a próxima ou mensalidades atrasadas serão cobradas conforme o plano alterado!" />
                                <div className='col-lg-12'>
                                    <ComboBoxCollection
                                        title='Selecionar plano'
                                        collection='planos'
                                        parentField='estacionamentoId'
                                        parentValue={this.state.estacionamentoId}
                                        onSelected={this.onPlanoSelected} />
                                </div>
                                {
                                    this.state.planoId !== null &&
                                    <div className='col-lg-12'>
                                        <h4>Novo plano selecionado</h4>
                                        <div>
                                            <strong>Nome: </strong> {this.state.planoNome}
                                        </div>
                                        <div>
                                            <strong>Horário inicio: </strong> {this.state.horarioInicio}
                                        </div>
                                        <div>
                                            <strong>Horário fim: </strong> {this.state.horarioFim}
                                        </div>
                                        <div>
                                            <strong>Valor: </strong>
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.planoValor} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <h4>{value}</h4>} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="btn-group m-3">
                                <button onClick={this.handleClosePlanModal} className='btn btn-secondary'>Fechar</button>
                                <button onClick={this.handleConfirmNewPlan} className='btn btn-success'>Confirmar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </NavBar >
                {
                    this.state.showModalAccessHistory && <TicketAnalyticsDialog tickets={this.state.tickets} onClose={this.onCloseAnalyticsDialog} />
                }
                {
                    this.state.showModalChages && <MonthlyChages key={this.state.chargeId} chargeSelected={this.state.chargeId} monthlyId={this.props.match.params.id} showManageButtons={true} onClose={this.closePaymentHistory} />
                }
                <Dialog maxWidth={'xs'} fullWidth onClose={this.closeModalGenerateChange} open={this.state.isOpenModalGenerateChange}>
                    <MuiDialogTitle className='text-center'>
                        Gerar cobrança para o mensalista
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <h4>Última cobrança</h4>
                        <h6>{Moment(this.state.lastCharge).format("DD/MM/YYYY")}</h6>
                        <h4>Valor do plano por vaga</h4>
                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.plano?.valor ?? 0} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => value} /> <p />
                        <label>Adicionar:</label>
                        <select className='form-select' value={this.state.monthToGenerateChange} onChange={(e) => this.setState({ monthToGenerateChange: e.target.value })}>
                            <option value="1">1 mês</option>
                            <option value="3">3 meses</option>
                            <option value="6">6 meses</option>
                            <option value="12">12 meses</option>
                            <option value="16">16 meses</option>
                            <option value="24">24 meses</option>
                        </select>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className="btn-group m-3">
                            <button onClick={this.closeModalGenerateChange} className='btn btn-secondary'>Fechar</button>
                            <button onClick={this.confirmGenerateChanges} className='btn btn-danger'>Confirmar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog onClose={() => this.setState({ isShowingMonthlyChangesUpdate: false })} fullWidth maxWidth={"xs"} open={this.state.isShowingMonthlyChangesUpdate}>
                    <MuiDialogTitle className='text-center'>
                        Atualizar cobranças do mensalista
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <AlertWarning message="Deseja atualizar as cobranças desse mensalista também?" />
                        <div>
                            <div>Você alterou o cadastro desse mensalista de {this.state.oldVagas} vagas para {this.state.vagas} vagas. </div>
                            <div>O seu plano atual está no valor de R${this.state.plano.valor} por vaga, se você concordar, irá atualizar as proximas mensalidades desse mensalista para <strong>R${parseFloat(this.state.plano.valor * this.state.vagas).toFixed(2)}</strong></div>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.updateMonthlyChanges(false)} className='btn btn-secondary'>Salvar sem atualizar</button>
                            <button onClick={() => this.updateMonthlyChanges(true)} className='btn btn-success'>Salvar e atualizar mensalidades</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog onClose={() => this.setState({ contractMonthlyModal: false })} fullWidth maxWidth={"md"} open={this.state.contractMonthlyModal}>
                    <MuiDialogTitle className='text-center'>
                        Contratos assinados
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        {
                            isNullOrEmpty(this.state.contracts) && <EmptyContent text='Nenhum contrato assinado.' />
                        }
                        {
                            !isNullOrEmpty(this.state.contracts) &&
                            <table className='table table-responsive table-striped'>
                                <thead>
                                    <tr>
                                        <th>Hash</th>
                                        <th>Assinatura</th>
                                        <th>Região</th>
                                        <th>IP</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.contracts.map((contract, index) => {
                                            return <tr key={index}>
                                                <td>{contract.id}</td>
                                                <td>{Moment(contract.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss A")}</td>
                                                <td>{contract.ip?.city}</td>
                                                <td>{contract.ip?.ip}</td>
                                                <td width={10}>
                                                    <Tooltip placement='top' title='Ver detalhes'>
                                                        <button onClick={(e) => this.showContractDetail(contract)} className='btn btn-secondary'>
                                                            <i class="fa-solid fa-file-signature" />
                                                        </button>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ contractMonthlyModal: false })} className='btn btn-secondary'>Fechar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog onClose={() => this.setState({ contractDetailModal: false })} fullWidth maxWidth={"md"} open={this.state.contractDetailModal}>
                    <MuiDialogTitle className='text-center'>
                        Detalhes do contrato
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <textarea className='form-control' rows={30} value={this.state.contractDetail?.contractHTML} disabled />
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ contractDetailModal: false })} className='btn btn-secondary'>Fechar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

class MonthlyChages extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            revendaId: getRevendaIdByPark(),
            clienteId: getCliente()?.id,
            estacionamentoId: getEstacionamento()?.id,
            isAdministrador: isAdministrador(),
            showManageButtons: props.showManageButtons,
            monthly: null,
            changesTotals: [],
            changes: [],
            loadingHistory: true,
            monthlyId: this.props.monthlyId,
            showModalToOpenCashier: false,
            paymentHistory: [],
            historiesEmpty: false,
            showModalAccessHistory: false,
            formasPagamentos: [],
            formaDePagamentoSelecionada: '',
            importModalIsOpen: false,
            state: "normal",
            importingStatus: "await",
            errorImport: [],
            paymentsValidate: [],
            isOpenManualDiscountModal: false,
            changesTotals: 0,
            changesProjectionTotals: 0,
            pendingTotals: 0,
            pendingQuantity: 0,
            dataBaixa: Moment(new Date()),
            showModalToRegisterPayment: false,
            showModalHistoryChange: false,
            chageSelected: null,
            changeDateSelected: null,
            cancelPaymentState: "fill"
        }
        this.loadData()
        this.loadParameters()
    }

    loadParameters = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ parameters: parameters })
    }

    loadData = async () => {
        this.setState({ loadingHistory: true })
        const monthly = await getMonthlyById(this.state.monthlyId)
        this.setState({ monthly: monthly })

        var changes = await getAllChagesByMonthlyId(this.state.monthlyId)
        this.setState({ historiesEmpty: changes.length === 0 })

        const changesTotals = changes.filter(e => e.payment).map(e => e.payment.total).reduce((a, b) => a + b, 0)

        this.setState({ changesTotals: changesTotals })
        this.setState({ changesProjectionTotals: changes.map(e => e.payment?.total ?? parseFloat(e.netValue)).reduce((a, b) => a + b, 0) })
        this.setState({ pendingTotals: changes.filter(e => e.status === "pending" && e.changeDate.toDate() < new Date()).map(e => parseFloat(e.netValue)).reduce((a, b) => a + b, 0) })
        this.setState({ pendingQuantity: changes.filter(e => e.status === "pending" && e.changeDate.toDate() < new Date()).length })

        this.setState({ paymentHistory: changes.sort((a, b) => (a.changeDate?.toDate() ?? a.createAt.toDate()) - (b.changeDate?.toDate() ?? b.createAt?.toDate())) });
        this.setState({ loadingHistory: false })

        this.setState({ chargeId: queryString("chargeId") })
    }

    handleClose = () => {
        this.props.onClose()
    };

    resetImport = () => {
        this.setState({ importModalIsOpen: false })
        sendClickButton("Mensalistas", "Fechou modal de importação de pagamentos do mensalista por excel.")
    }

    openModalImportHistory = async () => {
        const formasPagamentos = await getFormasPagamentosAtivasSemTags({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ formasPagamentos: formasPagamentos })

        this.setState({ importModalIsOpen: true })
        this.setState({ open: false })
        sendClickButton("Mensalistas", "Abriu modal de importação de pagamentos do mensalista por excel.")
    }

    validateFields = async (json, errosToImport) => {
        var paymentsValidate = []
        for (const [index, item] of json.entries()) {
            var messages = []
            messages.push(this.verifyFields(item))
            const resultMessages = messages.filter(e => e !== '')
            if (resultMessages.length > 0) {
                errosToImport.push({
                    line: (index + 1),
                    message: `${resultMessages.join(", ")}.`
                })
            } else {
                paymentsValidate.push(item)
            }
        }
        this.setState({ paymentsValidate: paymentsValidate })
        if (errosToImport.length > 0) {
            this.setState({ importingStatus: 'validating' })
        } else {
            this.setState({ importingStatus: 'no-errors' })
            if (paymentsValidate.length === 1) {
                this.setState({ importingMessage: `Encontramos 1 pagamento para importação.` })
            } else {
                this.setState({ importingMessage: `Encontramos ${paymentsValidate.length} pagamentos para importação.` })
            }
        }
    }

    handleCloseRegisterPayment = async () => {
        this.setState({ showModalToRegisterPayment: false })
    }

    handleCloseQRCode = async () => {
        this.handleCloseRegisterPayment()
        this.loadData()
    }

    printReceipt = async () => {
        const charge = await getChargeById({ id: this.state.chageSelected.id })
        const message = `Exportou em PDF o recibo de da mensalidade ${charge.id} do ${charge.clienteNome}.`
        sendLogByUser("Detalhes Mensalistas", message, true)
        const doc = <MonthlyChargeReportDocument charge={charge} key={charge.id} />
        await exportAndPrintPDF(doc)
        this.handleCloseQRCode()
    }

    verifyFields = (item) => {
        var messages = []
        const formaPagamento = this.state.formasPagamentos.filter(e => e.nome.toLowerCase() === item.FORMA_PAGAMENTO.toLowerCase())
        if (formaPagamento.length === 0) {
            messages.push(`Forma de pagamento ${item.FORMA_PAGAMENTO} não encontrada`)
        }
        const isValidateValid = Moment(item.DATA_PAGAMENTO, "DD/MM/YYYY").isValid()
        if (!isValidateValid) {
            messages.push(`A data de pagamento não esta correta`)
        }
        const pricePayment = parseFloat(item.VALOR ?? "0")
        if (pricePayment <= 0) {
            messages.push(` Informe o valor de pagamento`)
        }
        return messages.join(",")
    }

    importAgain = () => {
        this.setState({ importingMessage: "Importar pagamentos do mensalista" })
        this.setState({ importingStatus: 'await' })
    }

    forceImport = async () => {
        this.setState({ importingStatus: "importing" })
        const paymentsValidate = this.state.paymentsValidate
        const veiculos = await getVeiculos({ mensalistaId: this.state.id })
        sendLogByUser("Mensalistas", `Importando ${paymentsValidate.length} pagamentos`)
        var quantityImported = 0
        for (const [index, item] of paymentsValidate.entries()) {
            this.setState({ importingMessage: `Importando ${index + 1} de ${paymentsValidate.length} pagamentos` })
            this.setState({ currentImporting: `Pagamento de R$ ${item.VALOR.toFixed(2)} efetuado em ${Moment(item.DATA_PAGAMENTO).format("DD/MM/YYYY")}` })
            const formaPagamento = this.state.formasPagamentos.filter(e => e.nome.toLowerCase() === item.FORMA_PAGAMENTO.toLowerCase())[0]
            const pricesPayment = {
                dataFatura: item.DATA_PAGAMENTO,
                dataPagamento: item.DATA_PAGAMENTO,
                days: 30,
                originalPrice: parseFloat(item.VALOR),
                total: parseFloat(item.VALOR),
                formaPagamento: formaPagamento,
                porcentagem: 0,
                discountOrTax: 0,
                order: {
                    tipo: `ADMINISTRATIVO - ${formaPagamento.tipo.toUpperCase()}`,
                }
            }
            await this.registerMonthlyHistory({
                mensalista: {
                    clienteNome: this.state.nome,
                    plano: this.state.plano,
                    id: this.state.id
                },
                veiculos: veiculos,
                pagamento: pricesPayment,
                type: "imported",
                changeDate: item.DATA_PAGAMENTO,
                status: "payed",
                netValue: parseFloat(item.VALOR)
            })
            quantityImported++
        }
        this.setState({ importingMessage: `${quantityImported} importados com sucesso` })
        this.setState({ importingStatus: 'success' })
        sendLogByUser("Mensalistas", `${quantityImported} importados.`)
    }

    selectChageToPay = async (chage) => {
        const monthly = await getMonthlyById(this.state.monthlyId)
        this.setState({ ...monthly })
        this.setState({ chageSelected: chage })
        await this.registerPayment(chage)
    }

    showFutureCharges = async (chage) => {
        this.setState({ chageSelected: chage })
        this.setState({ showFutureCharges: true })
    }

    editChange = async (chage) => {
        this.setState({ chageSelected: chage })
        this.setState({ cashier: null })
        if (chage.cashier?.id && chage.cashier?.id !== "SEM_CAIXA_ABERTO") {
            this.setState({ selectedId: chage.cashier?.id })
        }
        this.setState({ changeDateSelected: Moment(chage.changeDate.toDate()) })
        this.setState({ netValueChage: chage.netValue })
        if (chage.status === "payed") {
            const valorPlano = chage.payment?.total
            if (valorPlano) {
                this.setState({ valorPlano: valorPlano })
            }
        }
        this.setState({ showModalEditChange: true })
    }

    showHistoryChage = async (chage) => {
        this.setState({ chageSelected: chage })
        this.setState({ showModalHistoryChange: true })
    }

    removeChage = async (chage) => {
        var confirm = window.confirm('Deseja mesmo remover esta cobrança?\n\nEsta ação não poderá ser desfeita');
        if (confirm) {
            await removeMonthlyChage(chage.id)
            this.loadData()
        }
    }

    showCancelPaymentModal = async (chage) => {
        this.setState({ cancelPaymentState: "fill" })
        this.setState({ currentChange: chage })
        this.setState({ isShowingCancelOnlinePayment: true })
    }

    hideCancelPaymentModal = () => {
        this.setState({ isShowingCancelOnlinePayment: false })
    }

    confirmCancelPayment = async () => {
        this.setState({ cancelPaymentState: "loading" })
        const change = this.state.currentChange
        const option = {
            method: "POST"
        }
        const response = await fetchFunction(`/gateway/payment/monthly/charge/${change.id}/refund`, option, this.state.parameters.X_API_KEY)
        if (response.success) {
            await this.loadData()
            toastSuccess(response.message)
            this.setState({ isShowingCancelOnlinePayment: false })
        } else {
            toastError(response.message)
            this.setState({ cancelPaymentState: "fill" })
        }
    }

    verifyPayment = async (change) => {
        this.setState({ loadingHistory: true })
        const option = {
            method: "GET"
        }
        const response = await fetchFunction(`/gateway/payment/monthly/charge/${change.id}/status`, option, this.state.parameters.X_API_KEY)
        if (response.success) {
            await this.loadData()
            toastSuccess(response.message)
        } else {
            this.setState({ loadingHistory: false })
            toastError(response.message)
        }
    }

    saveChange = async () => {
        var user = getUsuario()
        var chage = this.state.chageSelected
        var oldChage = Object.assign({}, chage);
        chage.netValue = this.state.netValueChage
        chage.changeDate = this.state.changeDateSelected.toDate()
        chage.alterAt = new Date()
        chage.alterBy = user.email
        chage.edited = true
        if (this.state.cashier) {
            chage.cashier = this.state.cashier
        }
        if (chage.history === undefined) {
            chage.history = []
        }
        if (chage.status === "payed") {
            chage.payment.total = this.state.valorPlano
        }
        chage.history.push({
            netValue: oldChage.netValue,
            newNetValue: this.state.valorPlano ?? this.state.netValueChage,
            changeDate: oldChage.changeDate,
            newChangeDate: this.state.changeDateSelected.toDate(),
            createAt: new Date(),
            user: {
                nome: user.nome,
                email: user.email
            }
        })
        await updateChageById({ id: chage.id, data: chage })
        sendClickButton("Detalhes Mensalistas", "Cobrança atualizada", chage)
        reloadWithAlert("Cobrança atualizada com sucesso!")
    }

    handleChangeDateSelected = (newValue) => {
        this.setState({ changeDateSelected: newValue })
    }

    updateChangeNetValue = (event, maskedvalue, floatvalue) => {
        this.setState({ netValueChage: floatvalue })
    }

    handleEditChange = () => {
        this.setState({ showModalEditChange: false })
    };

    registerPayment = async (chage) => {
        this.setState({ state: "normal" })
        this.setState({ showModalToOpenCashier: false })
        this.setState({ showModalToRegisterPayment: false })
        this.setState({ showModalToRegisterPayment: true })
        if (chage.spuns?.length > 0) {
            const totalSpuns = chage.spuns.flatMap(e => e.ticket.paymentsLogged).map(e => e.resumo.valorCobrado).reduce((a, b) => a + b, 0)
            this.setState({ valorPlano: (chage.netValue + totalSpuns) })
        } else {
            this.setState({ valorPlano: chage.netValue })
        }
        var novoVencimento = Moment(chage.changeDate.toDate()).add(1, 'M');
        novoVencimento = Moment(novoVencimento).set("date", parseInt(this.state.plano.diaVencimento));
        this.setState({ novoVencimento: novoVencimento })
        const formasPagamentos = await getFormasPagamentosAtivasSemTags({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ formasPagamentos: formasPagamentos })
    }

    openCashier = () => {
        this.setState({ showModalToOpenCashier: true })
    }

    onSelectedCashier = (cashier) => {
        this.setState({ cashier: cashier })
    }

    onChangeNewNoteForm = (data) => {
        this.setState({ monthlyNote: data })
    }

    confirmMonthlyPayment = async () => {
        this.setState({ state: "normal" })
        const formaDePagamentoSelecionada = this.state.formaDePagamentoSelecionada
        if (formaDePagamentoSelecionada === '') {
            toastWarning("Informa a forma de pagamento")
            return
        }
        if (this.state.monthlyNote) {
            if (this.state.monthlyNote.tomador) {
                if (!isValidDocument(this.state.monthlyNote.document)) {
                    toastWarning("Informe um documento válido.")
                    return
                }
                if (this.state.monthlyNote.name === "") {
                    toastWarning("Informe o nome do tomador.")
                    return
                }
            }
        }
        this.setState({ state: "loading" })
        const formaPagamento = this.state.formasPagamentos.filter(e => e.id === formaDePagamentoSelecionada)[0]
        const mensalista = {
            id: this.state.id,
            document: this.state.documento,
            nome: this.state.nome
        }
        var a = Moment(new Date());
        var b = Moment(this.state.chageSelected.changeDate.seconds * 1000);
        const days = a.diff(b, 'days')
        const valor = this.state.valorPlano
        const valorCusto = this.state.plano.valor
        var porcentagem = 0
        if ((valor - valorCusto) > 0) {
            porcentagem = parseFloat((((valor - valorCusto) / valor) * 100).toFixed(2))
        }
        const pricesPayment = {
            dataFatura: Moment(this.state.chageSelected.changeDate.seconds * 1000).toDate(),
            dataPagamento: this.state.dataBaixa.toDate(),
            days: parseInt(days),
            originalPrice: parseFloat(this.state.plano.valor),
            total: parseFloat(this.state.valorPlano),
            porcentagem: parseFloat(porcentagem),
            discountOrTax: parseFloat(this.state.plano.valor - this.state.valorPlano),
            formaPagamento: formaPagamento,
            order: {
                tipo: `ADMINISTRATIVO - ${formaPagamento.tipo.toUpperCase()}`,
            }
        }
        const raw = {
            estacionamentoId: this.state.estacionamentoId,
            cashierId: this.state.cashier.id,
            mensalista: mensalista,
            formaPagamento: formaPagamento,
            pricesPayment: {
                status: "PAYED",
                payment: pricesPayment
            }
        }
        const result = await registerMonthlyPayment(raw)
        if (result.status === true) {
            var change = this.state.chageSelected
            if (this.state.monthlyNote) {
                const message = `Acabou de registrar um pagamento para o mensalista ${this.state.nome} na cobrança ${change.id} com emissão de nota.`
                sendLogByUser("Detalhes Mensalistas", message, raw)
            } else {
                const message = `Acabou de registrar um pagamento para o mensalista ${this.state.nome}.`
                sendLogByUser("Detalhes Mensalistas", message, raw)
            }
            if (this.state.novoVencimento.toDate() > new Date()) {
                await updateVencimentoFatura({ mensalistaId: this.state.id, plano: this.state.plano, dataFatura: this.state.novoVencimento.toDate() })
            }
            const veiculos = await getVeiculos({ mensalistaId: this.state.id })
            change.vehicle = veiculos
            change.cashier = {
                id: result.caixa.id,
                openAt: result.caixa.openAt,
                openBy: result.caixa.openBy
            }
            change.status = "payed"
            change.monthlyCategory = this.state.monthlyCategory ? this.state.monthlyCategory : null
            change.plan = this.state.plano
            change.alterAt = new Date()
            change.alterBy = getUsuario().email
            change.payment = pricesPayment
            change.originPayment = "admin"
            await this.updateSpunsIfNeed({ change: change, cashier: result.caixa, wayPayment: formaPagamento })
            await updateChageById({ id: change.id, data: change })
            await this.generateNote(change)
            this.prepareURLOrCharge(change)
            this.setState({ state: "receipt" })
            await this.syncF360IfNeed(change)
        } else {
            sendError("Detalhes Mensalistas", "Erro ao tentar registrar um pagamento", raw)
            toastError(result.message)
            this.setState({ state: "normal" })
        }
    }

    syncF360IfNeed = async (item, individual) => {
        if (this.state.parameters.habilitarF360ERP) {
            if (individual) {
                const confirm = window.confirm(`Deseja mesmo sincronizar o pagamento com a F360?`)
                if (!confirm) {
                    return
                }
                toastLoading("Sincronizando pagamento...")
            }
            await F360SyncMonthlyPayments({ parameters: this.state.parameters, monthlyPayments: [item] })
            if (individual) {
                await delay(1000)
                toastDismissLoading()
                toastSuccess("Pagamento enviado para sincronismo!")
            }
        }
    }

    prepareURLOrCharge = async (item) => {
        const revenda = getRevenda()
        const url = `${revenda.siteTicket}/mensalista/cobranca/?id=${item.clienteId}&m=${item.id}`
        this.setState({ urlCharge: url })
    }

    updateSpunsIfNeed = async ({ change, cashier, wayPayment }) => {
        const spuns = change.spuns ?? []
        for (const spun of spuns) {
            const ticket = await getTicketById({ id: spun.ticket.id })
            ticket.caixas = []
            ticket.caixas.push(cashier.id)
            for (const payment of ticket.paymentsLogged ?? []) {
                payment.caixaId = cashier.id
                payment.formaPagamento = wayPayment
            }
            ticket.isSpun = false
            ticket.spunByChange = null
            await updateTicket(ticket.id, ticket)
        }
    }

    generateNote = async (change) => {
        if (this.state.monthlyNote) {
            const valor = parseFloat(this.state.valorPlano ?? "0")
            if (valor > 0) {
                const user = getUsuarioCompact()
                var data = {
                    revendaId: this.state.revendaId,
                    clienteId: this.state.clienteId,
                    estacionamentoId: this.state.estacionamentoId,
                    identifier: change.id,
                    type: "mensalista",
                    createdBy: user,
                    createdAt: new Date(),
                    status: [{
                        createdAt: new Date(),
                        status: "NOTE_PROCESSING",
                        createdBy: user
                    }],
                    currentStatus: "NOTE_PROCESSING",
                    valor: valor,
                    issuer: this.state.monthlyNote.tomador
                }
                if (this.state.monthlyNote.tomador) {
                    data.document = this.state.monthlyNote.document
                    data.name = this.state.monthlyNote.name
                }
                await addNewNote({ data: data })
            }
        }
    }

    closeImportModal = () => {
        reloadWindow()
    }

    handleCloseManualDiscountModal = () => {
        this.setState({ isOpenManualDiscountModal: false })
    }

    showManualDiscount = (item) => {
        this.setState({ isOpenManualDiscountModal: true })
        this.setState({ discountSelected: item })
    }

    showReceipt = async (item) => {
        const message = `Exportou em PDF o recibo de da mensalidade ${item.id} do ${item.clienteNome}.`
        sendLogByUser("Mensalistas", message, item)
        const charge = await getChargeById({ id: item.id })
        const doc = <MonthlyChargeReportDocument charge={charge} key={charge.id} />
        await exportAndPrintPDF(doc)

    }

    handleNovoVencimentoDateChange = (newValue) => {
        this.setState({ novoVencimento: newValue })
    }

    handleBaixaDateChange = (newValue) => {
        this.setState({ dataBaixa: newValue })
    }

    handleImportFileChange = (e) => {
        this.setState({ importingStatus: 'validating' })
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        const prepareMonthliesF = this.prepareMonthlies
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            const json = XLSX.utils.sheet_to_json(ws, { header: 2, cellDates: true });
            prepareMonthliesF(json)
        };
        reader.readAsBinaryString(f)
    }

    prepareMonthlies = async (json) => {
        if (json.length !== 0) {
            const firstRow = json[0]
            if (firstRow.VALOR === undefined ||
                firstRow.DATA_PAGAMENTO === undefined) {
                this.setState({ importingMessage: "Informe uma planilha válida com todos os dados preenchidos." })
                this.setState({ importingStatus: "empty" })
            } else {
                this.validateJSONToImport(json)
            }
        } else {
            this.setState({ importingMessage: "Nenhum registro encontrado no arquivo selecionado." })
            this.setState({ importingStatus: "empty" })
        }
    }

    validateJSONToImport = (json) => {
        var errosToImport = []
        this.validateFields(json, errosToImport)
        this.setState({ errorImport: errosToImport })
        if (errosToImport.length > 0) {
            this.setState({ importingStatus: 'validating' })
            if (errosToImport.length === 1) {
                this.setState({ importingMessage: `Existe um alerta na planilha selecionada.` })
            } else {
                this.setState({ importingMessage: `Existem ${errosToImport.length} registros com alertas na planilha selecionada.` })
            }
        } else {
            this.setState({ importingStatus: 'no-errors' })
        }
    }

    updateValorPlano = (event, maskedvalue, floatvalue) => {
        this.setState({ valorPlano: floatvalue })
    }

    onSelectedCashier = (cashier) => {
        this.setState({ cashier: cashier })
    }

    copyChargeLink = (item) => {
        const revenda = getRevenda()
        const url = `${revenda.siteTicket}/mensalista/cobranca/?id=${item.clienteId}&m=${item.id}`
        copyLinkAndShare({
            title: item.clienteNome,
            message: "Cobrança da mensalidade",
            url: url,
        })
        const message = `Tocou em copiar link em uma cobrança do mensalista ${item.clienteNome} : ${item.id}.`
        sendLogByUser("Detalhes Mensalistas", message)
    }

    sendBillByWhatsApp = async (item) => {
        var confirm = window.confirm(`Deseja mesmo enviar uma mensagem com o link de cobrança para este mensalista?`)
        if (confirm) {
            const option = {
                method: 'POST'
            }
            const response = await fetchFunction(`/monthly/charge/${item.id}/whatsApp`, option)
            var message = ""
            if (response.success) {
                toastSuccess(response.message)
                message = `Tocou em enviar link via WhatsApp em uma cobrança do mensalista ${item.clienteNome} : ${item.id} | ${response.message}.`
            } else {
                toastError(response.message)
                message = `Tocou em enviar link via WhatsApp em uma cobrança do mensalista ${item.clienteNome} : ${item.id} | ${response.message}.`
            }
            sendLogByUser("Detalhes Mensalistas", message, item)
        }
    }

    showMessagesSent = async (item) => {
        this.setState({ referenceId: item.id })
        this.setState({ showWhatsAppMessagesModal: true })
    }

    hideWhatsAppMessages = () => {
        this.setState({ showWhatsAppMessagesModal: false })
    }

    showVerifyNote = async (item) => {
        toastLoading("Verificando nota...")
        const option = {
            method: "POST",
            body: {
                id: item.id
            }
        }
        const response = await fetchFunction(`/newnote/verify`, option, this.state.parameters.X_API_KEY)
        toastDismissLoading()
        if (response.success) {
            this.loadData()
            toastSuccess(response.message)
        } else {
            toastError(response.message)
        }
    }

    showGenerateNote = async (item) => {
        const documento = normalizedField(this.state.monthly.documento)
        if (!isNullOrEmpty(documento) && !isValidDocument(documento)) {
            toastWarning("Informe um documento válido no cadastro do mensalista para gerar uma nota fiscal.")
            return
        }
        const confirm = window.confirm(`Deseja mesmo gerar uma nota fiscal para este pagamento?`)
        if (!confirm) {
            return
        }
        const noteFound = await getNewNoteByIdentifier({ identifier: item.id })
        if (!noteFound) {
            toastLoading("Gerando nota...")
            const user = getUsuarioCompact()
            var data = {
                revendaId: item.revendaId,
                clienteId: item.clienteId,
                estacionamentoId: item.estacionamentoId,
                identifier: item.id,
                noteType: "NFS-e",
                type: "mensalista",
                createdBy: user,
                createdAt: new Date(),
                status: [{
                    createdAt: new Date(),
                    status: "NOTE_PROCESSING",
                    createdBy: user
                }],
                currentStatus: "NOTE_PROCESSING",
                valor: item.payment.total,
                name: this.state.monthly.nome,
                document: documento
            }
            const note = await addNewNote({ data: data })
            const option = {
                method: 'POST',
                body: {
                    id: note.id
                }
            }
            const response = await fetchFunction(`/newnote/process`, option)
            if (response.success) {
                reloadWithAlert(response.message)
            } else {
                toastWarning(response.message)
            }
        } else {
            this.showVerifyNote(item)
        }
    }

    removePaymentChange = async (index) => {
        const confirm = window.confirm(`Deseja mesmo remover esta alteração?`)
        if (!confirm) {
            return
        }
        const chage = this.state.chageSelected
        const changes = chage.history
        changes.splice(index, 1)
        chage.history = changes
        if (isNullOrEmpty(chage.history)) {
            this.setState({ showModalHistoryChange: false })
        }
        this.setState({ chageSelected: chage })
        await updateChageById({ id: chage.id, data: chage })
    }

    render() {
        return (
            <>
                <Dialog onClose={this.handleClose} maxWidth={"xl"} open={true}>
                    {
                        !this.state.loadingHistory &&
                        <MuiDialogTitle>
                            <div className="card-header d-flex justify-content-between align-items-center">
                                Cobranças do mensalista
                                {
                                    this.props.showManageButtons === true &&
                                    <span className='btn-group'>
                                        <button type="button" onClick={this.openModalImportHistory} className="btn btn-sm btn-primary">
                                            <i className="fas fa-file-import mx-2" />
                                            Importar
                                        </button>
                                        <HelpButton />
                                    </span>
                                }
                            </div>
                        </MuiDialogTitle>
                    }
                    <MuiDialogContent>
                        {
                            this.state.loadingHistory ? <Loading /> :
                                this.state.historiesEmpty === true ? <EmptyContent text="Nenhum pagamento efetuado até o momento." /> :
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <div className="card text-light bg-success mb-3">
                                                <div className="card-body">
                                                    <h5 className="card-title text-center text-white">Valor total pago</h5>
                                                    <h3 className="card-text text-center text-white">
                                                        {toCurrency(this.state.changesTotals)}
                                                    </h3>
                                                    <h5 className='monthly-card monthly-card-green'>
                                                        {this.state.paymentHistory.filter(e => e.payment).length} Pagamentos
                                                    </h5>
                                                    <div className="text-center">
                                                        <small className="card-text text-white">Todos os pagamentos</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className="card text-light bg-danger mb-3">
                                                <div className="card-body">
                                                    <h5 className="card-title text-center text-white">Valor total pendente</h5>
                                                    <h3 className="card-text text-center text-white">
                                                        {toCurrency(this.state.pendingTotals)}
                                                    </h3>
                                                    <h5 className='monthly-card monthly-card-red'>
                                                        {this.state.pendingQuantity} Pendentes
                                                    </h5>
                                                    <div className="text-center">
                                                        <small className="card-text text-white">Pagamentos pendentes</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className="card text-light bg-secondary mb-3">
                                                <div className="card-body">
                                                    <h5 className="card-title text-center text-white">Previsto até fim do contrato</h5>
                                                    <h3 className="card-text text-center text-white">
                                                        {toCurrency(this.state.changesProjectionTotals)}
                                                    </h3>
                                                    <h5 className='monthly-card monthly-card-gray'>
                                                        {this.state.paymentHistory.length} Cobranças
                                                    </h5>
                                                    <div className="text-center">
                                                        <small className="card-text text-white">Todas as cobranças geradas</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-12'>
                                            <table className="table table-responsive table-striped">
                                                <thead>
                                                    <tr>
                                                        <td width={10} align='right'></td>
                                                        {
                                                            this.state.parameters.habilitarF360ERP &&
                                                            <td width={10} align='center'><strong>F360</strong></td>
                                                        }
                                                        <td width={30} align='center'><strong>Origem</strong></td>
                                                        <td width={30} align='center'><strong>Status</strong></td>
                                                        <td align='center'><strong>Vencimento</strong></td>
                                                        <td align='center'><strong>Data pagamento</strong></td>
                                                        <td align='center'><strong>Valor cobrado</strong></td>
                                                        <td align='center'><strong>Valor pago</strong></td>
                                                        <td width={10} align='right'></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.paymentHistory.map((item, index) =>
                                                            <>
                                                                {
                                                                    item.status === "payed" &&
                                                                    <tr key={index} className={item.id === this.state.chargeId ? 'bg-secondary text-white' : ''}>
                                                                        <td align='left'>
                                                                            <div className='btn-group'>
                                                                                <Tooltip title="Mensagens enviadas" placement="top">
                                                                                    <button type="button" onClick={() => { this.showMessagesSent(item) }} className="btn btn-success">
                                                                                        <i className="fas fa-comments" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                                <Tooltip title="Copiar link da cobrança" placement="top">
                                                                                    <button onClick={(e) => this.copyChargeLink(item)} className="btn btn-success text-white">
                                                                                        <i className="fas fa-share-alt" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            this.state.parameters.habilitarF360ERP &&
                                                                            <td>
                                                                                <div className='btn-group'>
                                                                                    {
                                                                                        item.F360SyncStatus === "synced" && parseInt(item.F360SyncCoupon) > 0 ?
                                                                                            <Tooltip title={`Pagamento sincronizado - cupom ${item.F360SyncCoupon}`} placement="top">
                                                                                                <button type="button" className="btn btn-success">
                                                                                                    <i className="fas fa-check-circle" />
                                                                                                </button>
                                                                                            </Tooltip> :
                                                                                            <Tooltip title="Sincronizar pagamento" placement="top">
                                                                                                <button type="button" onClick={() => { this.syncF360IfNeed(item, true) }} className="btn btn-warning text-white">
                                                                                                    <i className="fas fa-sync" />
                                                                                                </button>
                                                                                            </Tooltip>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>
                                                                            {
                                                                                item.type === "imported" && <span className="badge text-bg-secondary text-white w-100">Importado</span>
                                                                            }
                                                                            {
                                                                                item.type === "admin-added" && <span className="badge text-bg-primary text-white w-100">Administrativo</span>
                                                                            }
                                                                            {
                                                                                item.type === "public-site-added" && <span className="badge text-bg-info text-white w-100">Formulário online</span>
                                                                            }
                                                                            {
                                                                                item.type === undefined && <span className="badge text-bg-success text-white w-100">Aplicativo</span>
                                                                            }
                                                                        </td>
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>
                                                                            <Tooltip title={item.id} placement="top">
                                                                                <span className="badge text-bg-success w-100">Pago</span>
                                                                            </Tooltip>
                                                                        </td>
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>{Moment((item.payment?.dataFatura?.seconds ?? 0) * 1000).format("DD/MM/YYYY")}</td>
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>{Moment((item.payment?.dataPagamento?.seconds ?? 0) * 1000).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>{toCurrency(item.netValue ?? item.payment?.total ?? 0)}</td>
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>{toCurrency(item.payment?.total ?? 0)}</td>
                                                                        <td align='right'>
                                                                            <div className='btn-group'>
                                                                                {
                                                                                    item.spuns?.length > 0 &&
                                                                                    <Tooltip title="Visualizar cobranças lançadas" placement="top">
                                                                                        <button onClick={(e) => this.showFutureCharges(item)} className="btn btn-warning text-white">
                                                                                            <i className="fas fa-chart-bar" />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                }
                                                                                {
                                                                                    this.state.parameters?.habilitarEmissaoNota && <>
                                                                                        {
                                                                                            item.newNote?.currentStatus === "NOTE_GENERATED" &&
                                                                                            <Tooltip title="Visualizar nota" placement="top">
                                                                                                <a href={item.newNote.pdf_nfse} target='_blank' className="btn btn-success">
                                                                                                    <i className="fas fa-file-pdf" />
                                                                                                </a>
                                                                                            </Tooltip>
                                                                                        }
                                                                                        {
                                                                                            item.newNote?.currentStatus === "NOTE_PROCESSING" &&
                                                                                            <Tooltip title="Nota em processamento" placement="top">
                                                                                                <button className="btn btn-secondary text-white" onClick={(e) => this.showVerifyNote(item)}>
                                                                                                    <i className="fas fa-file-pdf" />
                                                                                                </button>
                                                                                            </Tooltip>
                                                                                        }
                                                                                        {
                                                                                            item.newNote?.currentStatus === "NOTE_ERROR" &&
                                                                                            <Tooltip title="Emitir nota" placement="top">
                                                                                                <button className="btn btn-danger text-white" onClick={(e) => this.showGenerateNote(item)}>
                                                                                                    <i className="fas fa-file-pdf" />
                                                                                                </button>
                                                                                            </Tooltip>
                                                                                        }
                                                                                        {
                                                                                            isNullOrEmpty(item.newNote) &&
                                                                                            <Tooltip title="Emitir nota" placement="top">
                                                                                                <button className="btn btn-secondary text-white" onClick={(e) => this.showGenerateNote(item)}>
                                                                                                    <i className="fas fa-file-pdf" />
                                                                                                </button>
                                                                                            </Tooltip>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    (item.manualDiscount !== undefined && item.manualDiscount !== null) &&
                                                                                    <Tooltip title="Foi adicionado um desconto manual para este pagamento." placement="top">
                                                                                        <button type="button" onClick={(e) => this.showManualDiscount(item)} className="btn btn-danger">
                                                                                            <i className="fas fa-hand-holding-usd" />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                }
                                                                                {
                                                                                    item.cashier !== null ? <>
                                                                                        {
                                                                                            item.cashier.id === "SEM_CAIXA_ABERTO" ?
                                                                                                <UserPermissionButton className="btn btn-danger" action={(e) => this.editChange(item)} permission="MONTHLY_CHARGE_EDIT">
                                                                                                    <Tooltip title="Sem caixa associado" placement="top">
                                                                                                        <i className="fas fa-eye" />
                                                                                                    </Tooltip>
                                                                                                </UserPermissionButton> : <Tooltip title="Existe um caixa associado a este pagamento." placement="top">
                                                                                                    <a href={'/caixas/' + item.cashier?.id} target='_blank' className="btn btn-primary">
                                                                                                        <i className="fas fa-eye" />
                                                                                                    </a>
                                                                                                </Tooltip>
                                                                                        }
                                                                                    </> : <Tooltip title="Não existe um caixa associado a este pagamento" placement="top">
                                                                                        <a href="#" className="btn btn-secondary">
                                                                                            <i className="fas fa-eye" />
                                                                                        </a>
                                                                                    </Tooltip>
                                                                                }
                                                                                {
                                                                                    !isNullOrEmpty(item.history) &&
                                                                                    <Tooltip title="Visualizar histórico de edição" placement="top">
                                                                                        <button onClick={(e) => this.showHistoryChage(item)} className="btn btn-warning text-white">
                                                                                            <i className="fas fa-bars" />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                }
                                                                                <Tooltip title="Imprimir recibo" placement="top">
                                                                                    <>
                                                                                        <div style={{ display: "none" }}>
                                                                                            <PrinterMonthlyChargeComponent
                                                                                                charge={item}
                                                                                                park={getEstacionamento()}
                                                                                                ref={el => (this.componentRef = el)} />
                                                                                        </div>
                                                                                        <ReactToPrint
                                                                                            trigger={() => {
                                                                                                return <button type="button" className='btn btn-secondary text-white'><i className='fas fa-print' /></button>
                                                                                            }}
                                                                                            content={() => this.componentRef}
                                                                                        />
                                                                                    </>
                                                                                </Tooltip>
                                                                                <UserPermissionButton className="btn btn-secondary" action={(e) => this.editChange(item)} permission="MONTHLY_CHARGE_EDIT">
                                                                                    <Tooltip title="Editar cobrança" placement="top">
                                                                                        <i className="fas fa-edit" />
                                                                                    </Tooltip>
                                                                                </UserPermissionButton>
                                                                                {
                                                                                    this.state.showManageButtons && <>
                                                                                        {
                                                                                            item.pixQRCode ?
                                                                                                <Tooltip title="Cancelar pagamento" placement="top">
                                                                                                    <button onClick={(e) => this.showCancelPaymentModal(item)} className="btn btn-danger">
                                                                                                        <i className="fas fa-trash" />
                                                                                                    </button>
                                                                                                </Tooltip> :
                                                                                                <Tooltip title="Remover cobrança" placement="top">
                                                                                                    <button onClick={(e) => this.removeChage(item)} className="btn btn-danger">
                                                                                                        <i className="fas fa-trash" />
                                                                                                    </button>
                                                                                                </Tooltip>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    item.status === "pending" &&
                                                                    <tr key={index} className={item.id === this.state.chargeId ? 'bg-warning text-white' : ''}>
                                                                        <td align='left'>
                                                                            <div className='btn-group'>
                                                                                <Tooltip title="Mensagens enviadas" placement="top">
                                                                                    <button type="button" onClick={() => { this.showMessagesSent(item) }} className="btn btn-success">
                                                                                        <i className="fas fa-comments" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                                <Tooltip title="Copiar link da cobrança" placement="top">
                                                                                    <button onClick={(e) => this.copyChargeLink(item)} className="btn btn-success text-white">
                                                                                        <i className="fas fa-share-alt" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                                {
                                                                                    isNullOrEmpty(this.state.monthly?.celular) ?
                                                                                        <Tooltip title="Enviar no WhatsApp" placement="top">
                                                                                            <button type="button" onClick={() => { toastError("Este mensalista não tem número de celular cadastrado para receber a mensagem via WhatsApp.") }} className="btn btn-danger">
                                                                                                <i className="fa-brands fa-whatsapp" />
                                                                                            </button>
                                                                                        </Tooltip> :
                                                                                        <Tooltip title="Enviar no WhatsApp" placement="top">
                                                                                            <button type="button" onClick={() => { this.sendBillByWhatsApp(item) }} className="btn btn-success">
                                                                                                <i className="fa-brands fa-whatsapp" />
                                                                                            </button>
                                                                                        </Tooltip>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            this.state.parameters.habilitarF360ERP &&
                                                                            <td>
                                                                                <Tooltip title="Aguardando pagamento" placement="top">
                                                                                    <button type="button" onClick={() => { toastWarning("Iremos sincronizar o pagamento no momento que esta cobrança for paga.") }} className="btn btn-secondary text-white">
                                                                                        <i className="fas fa-ellipsis-h" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </td>
                                                                        }
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>
                                                                            {
                                                                                item.type === "imported" && <span className="badge text-bg-secondary text-white btn-block w-100">Importado</span>
                                                                            }
                                                                            {
                                                                                item.type === "public-site-added" && <span className="badge text-bg-info text-white w-100">Formulário online</span>
                                                                            }
                                                                            {
                                                                                item.type === "admin-added" && <span className="badge text-bg-primary text-white btn-block w-100">Administrativo</span>
                                                                            }
                                                                            {
                                                                                item.type === undefined && <span className="badge text-bg-success text-white btn-block w-100">Aplicativo</span>
                                                                            }
                                                                        </td>
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>
                                                                            <Tooltip title={item.id} placement="top">
                                                                                {
                                                                                    item.pixQRCode ?
                                                                                        <span className="badge text-bg-warning btn-block text-white w-100">Aguardando pagamento</span>
                                                                                        : <>
                                                                                            {
                                                                                                (item.changeDate.toDate() >= new Date()) ?
                                                                                                    <Tooltip title={item.id} placement="top"><span className="badge text-bg-secondary btn-block w-100">Cobrança gerada</span></Tooltip> :
                                                                                                    <Tooltip title={item.id} placement="top"><span className="badge text-bg-danger btn-block w-100">Pendente</span></Tooltip>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </Tooltip>
                                                                        </td>
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>{Moment(item.changeDate.toDate()).format("DD/MM/YYYY")}</td>
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>-</td>
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>
                                                                            {
                                                                                !isNullOrEmpty(item.spuns) ?
                                                                                    <Tooltip title={`Valor da cobrança original: R$${item.netValue.toFixed(2)}`} placement="top">
                                                                                        <span><strong className='text-warning'>{toCurrency(item.netValue + item.spuns.map(e => e.ticket?.paymentsLogged).flatMap(e => e).map(e => e.resumo?.valorCobrado ?? 0).reduce((a, b) => a + b, 0))}</strong></span>
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <span>{toCurrency(item.netValue)}</span>
                                                                            }
                                                                        </td>
                                                                        <td align='center' className={item.id === this.state.chargeId ? 'text-white' : ''}>-</td>
                                                                        <td align='right'>
                                                                            <div className='btn-group'>
                                                                                {
                                                                                    item.pixQRCode &&
                                                                                    <Tooltip title="Verificar pagamento" placement="top">
                                                                                        <button onClick={(e) => this.verifyPayment(item)} className="btn btn-primary text-white">
                                                                                            <i className="fas fa-check-double" />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                }
                                                                                {
                                                                                    item.spuns?.length > 0 &&
                                                                                    <Tooltip title="Visualizar cobranças lançadas" placement="top">
                                                                                        <button onClick={(e) => this.showFutureCharges(item)} className="btn btn-warning text-white">
                                                                                            <i className="fas fa-chart-bar" />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                }
                                                                                {
                                                                                    this.state.showManageButtons &&
                                                                                    <UserPermissionButton
                                                                                        permission="MONTHLY_CHARGE_REGISTER"
                                                                                        className="btn btn-success"
                                                                                        action={(e) => this.selectChageToPay(item)}>
                                                                                        <Tooltip title="Registrar pagamento" placement="top">
                                                                                            <i className="fas fa-sack-dollar" />
                                                                                        </Tooltip>
                                                                                    </UserPermissionButton>
                                                                                }
                                                                                {
                                                                                    !isNullOrEmpty(item.history) &&
                                                                                    <Tooltip title="Visualizar histórico de edição" placement="top">
                                                                                        <button onClick={(e) => this.showHistoryChage(item)} className="btn btn-warning text-white">
                                                                                            <i className="fas fa-bars" />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                }
                                                                                <UserPermissionButton
                                                                                    permission="MONTHLY_CHARGE_EDIT"
                                                                                    className="btn btn-secondary"
                                                                                    action={(e) => this.editChange(item)}>
                                                                                    <Tooltip title="Editar cobrança" placement="top">
                                                                                        <i className="fas fa-edit" />
                                                                                    </Tooltip>
                                                                                </UserPermissionButton>
                                                                                {
                                                                                    this.state.showManageButtons &&
                                                                                    <UserPermissionButton
                                                                                        permission="MONTHLY_CHARGE_REMOVE"
                                                                                        className="btn btn-danger"
                                                                                        action={(e) => this.removeChage(item)}>
                                                                                        <Tooltip title="Remover cobrança" placement="top">
                                                                                            <i className="fas fa-trash" />
                                                                                        </Tooltip>
                                                                                    </UserPermissionButton>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                        }
                    </MuiDialogContent>
                    {
                        !this.state.loadingHistory &&
                        <MuiDialogActions>
                            <div className="btn-group">
                                <button onClick={this.handleClose} className='btn btn-secondary m-3'>Fechar</button>
                            </div>
                        </MuiDialogActions>
                    }
                </Dialog>
                <Dialog fullWidth maxWidth="lg" onClose={this.hideWhatsAppMessages} open={this.state.showWhatsAppMessagesModal}>
                    <MuiDialogContent>
                        <WhatsAppMessageListBody referenceId={this.state.referenceId} />
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <button onClick={this.hideWhatsAppMessages} className='btn btn-secondary m-3'>Fechar</button>
                    </MuiDialogActions>
                </Dialog>
                <Dialog fullWidth maxWidth="xs" open={this.state.isShowingCancelOnlinePayment}>
                    {
                        this.state.cancelPaymentState === "loading" && <div className='text-center'>
                            <Loading />
                        </div>
                    }
                    {
                        this.state.cancelPaymentState === "fill" && <>
                            <MuiDialogTitle className='text-center'>
                                <h5>Deseja mesmo estornar o pagamento desta cobrança?</h5>
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='row'>
                                    <label>Data do pagamento</label><br />
                                    <h5>{Moment(this.state.currentChange?.payment?.dataPagamento?.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss A")}</h5>
                                    <label>Valor</label><br />
                                    <h5>{toCurrency(this.state.currentChange?.payment?.total)}</h5>
                                    {
                                        isAdministrador() &&
                                        <AlertDanger tag="Administrativo">
                                            <label>Integradora</label><br />
                                            <h5>{this.state.currentChange?.pixQRCode?.gateway}</h5>
                                        </AlertDanger>
                                    }
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={this.hideCancelPaymentModal} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={this.confirmCancelPayment} className='btn btn-danger'>Confirmar</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>
                <Dialog maxWidth={'md'} onClose={this.resetImport} open={this.state.importModalIsOpen}>
                    <MuiDialogTitle className='text-center'>
                        {this.state.importingMessage}
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        {
                            this.state.importingStatus === "importing" &&
                            <>
                                <h4 className='text-center'>{this.state.currentImporting}...</h4>
                                <h6 className='text-center my-3'>Isso pode levar alguns segundos.</h6>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="button" onClick={this.closeImportModal} className="btn btn-danger btn-sm my-3">
                                        Cancelar
                                    </button>
                                </div>
                            </>
                        }
                        {
                            this.state.importingStatus === "success" &&
                            <>
                                <h4 className='text-center'>Todos os registros de pagamentos foram importados com sucesso!</h4>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="button" onClick={this.closeImportModal} className="btn btn-success btn-sm my-3">
                                        Fechar
                                    </button>
                                </div>
                            </>
                        }
                        {
                            this.state.importingStatus === "validating" &&
                            <>
                                {
                                    this.state.errorImport.length === 0 && <Loading />
                                }
                                {
                                    this.state.errorImport.length > 0 &&
                                    <>
                                        <table className="table table-responsive table-striped">
                                            <thead>
                                                <tr>
                                                    <td align='center'><strong>Linha</strong></td>
                                                    <td width={400}><strong>Mensagem</strong></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.errorImport.map((item, index) =>
                                                        <tr key={index}>
                                                            <td align='center'>{item.line}</td>
                                                            <td>{item.message}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <div className='row'>
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <div className="btn-group">
                                                    <button type="button" onClick={this.importAgain} className="btn btn-sm btn-secondary">
                                                        <i className="fas fa-long-arrow-left mx-2" />
                                                        Voltar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                        {
                            this.state.importingStatus === "no-errors" &&
                            <div className='row'>
                                <table className="table table-responsive table-striped">
                                    <thead>
                                        <tr>
                                            <td align='center'><strong>Valor</strong></td>
                                            <td align='center'><strong>Data pagamento</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.paymentsValidate.map((item, index) =>
                                                <tr key={index}>
                                                    <td align='center'>
                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.VALOR} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <span>{value}</span>} />
                                                    </td>
                                                    <td align='center'>{(item.DATA_PAGAMENTO === undefined || item.DATA_PAGAMENTO === "") ? "-" : Moment(item.DATA_PAGAMENTO).format("DD/MM/YYYY")}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <div className="btn-group">
                                        <button type="button" onClick={this.importAgain} className="btn btn-sm btn-secondary">
                                            <i className="fas fa-long-arrow-left mx-2" />
                                            Voltar
                                        </button>
                                        {
                                            this.state.paymentsValidate.length > 0 && <>
                                                <button type="button" onClick={this.forceImport} className="btn btn-sm btn-warning text-white">
                                                    <i className="fas fa-file-import mx-2" />
                                                    {
                                                        this.state.paymentsValidate.length === 1 && <span>Importar 1 pagamento</span>
                                                    }
                                                    {
                                                        this.state.paymentsValidate.length > 1 && <span>Importar os {this.state.paymentsValidate.length} pagamentos</span>
                                                    }
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.importingStatus === "empty" &&
                            <>
                                <div className="alert alert-secondary" role="alert">
                                    Verifique se a planilha foi exportada na extensão .xlsx e se existe registros dentro dela.
                                </div>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="button" onClick={this.importAgain} className="btn btn-sm btn-secondary">
                                        <i className="fas fa-long-arrow-left mx-2" />
                                        Voltar
                                    </button>
                                </div>
                            </>
                        }
                        {
                            this.state.importingStatus === 'await' &&
                            <div>
                                <div className="alert alert-secondary" role="alert">
                                    Antes de começarmos você deve baixar a nossa planilha de exemplo e <br /> preencher com os dados de histórico do pagamento deste mensalista. <p />
                                    <strong><a rel="noopener noreferrer" href="https://firebasestorage.googleapis.com/v0/b/selfparking-prod-26925.appspot.com/o/files%2Fothers%2Fimportar_historico_mensalistas_exemplo.xlsx?alt=media&token=fa52a9a2-b7f9-4e36-afd5-35d836223593" target="_blank" download>Baixar planilha de exemplo</a></strong>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="formFile" className="form-label">Selecionar arquivo</label>
                                    <div>
                                        <input type="file" accept=".xlsx" onChange={this.handleImportFileChange} id="formFile" />
                                    </div>
                                </div>
                                {/* <FileUploader handleChange={this.handleDataFaturatDateChange} name="file" types={["xlsx"]} /> */}
                            </div>
                        }
                    </MuiDialogContent>
                </Dialog>
                {
                    this.state.showModalToOpenCashier === true && <OpenCashier showModal={this.state.showModalToOpenCashier} />
                }
                <Dialog onClose={this.handleCloseManualDiscountModal} maxWidth={'sm'} open={this.state.isOpenManualDiscountModal}>
                    <MuiDialogTitle className='text-center'>
                        Desconto manual
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        {
                            this.state.discountSelected !== undefined && <>
                                <span className='text-center'>Foi adicionado um desconto manual neste pagamento!</span>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div><strong>Valor do desconto</strong></div>
                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.discountSelected.manualDiscount.discount} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <div>{value}</div>} />
                                    </div>
                                    <div className='col-lg-12'>
                                        <div><strong>Aprovador</strong></div>
                                        <div>{this.state.discountSelected.manualDiscount.user.email}</div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div><strong>Motivo</strong></div>
                                        <div>{this.state.discountSelected.manualDiscount.reason}</div>
                                    </div>
                                </div>
                            </>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className="btn-group m-3">
                            <button onClick={this.handleCloseManualDiscountModal} className='btn btn-secondary'>Fechar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog onClose={(e) => this.setState({ showModalHistoryChange: false })} maxWidth={'md'} fullWidth open={this.state.showModalHistoryChange}>
                    <MuiDialogTitle className='text-center'>
                        Histórico de mudanças nesta cobrança
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td><strong>Usuário</strong></td>
                                        <td align='center'><strong>Valor antigo</strong></td>
                                        <td align='center'><strong>Data cobrança antiga</strong></td>
                                        <td align='center'><strong>Novo valor</strong></td>
                                        <td align='center'><strong>Nova data cobrança</strong></td>
                                        <td align='center'><strong>Data alteração</strong></td>
                                        {
                                            this.state.isAdministrador &&
                                            <td align='right' width={10}></td>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.chageSelected?.history?.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.user.nome}</td>
                                                <td align='center'><NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.netValue} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></td>
                                                <td align='center'>{Moment(item.changeDate.toDate()).format("DD/MM/YYYY")}</td>
                                                <td align='center'><NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.newNetValue} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></td>
                                                <td align='center'>{Moment(item.newChangeDate.toDate()).format("DD/MM/YYYY")}</td>
                                                <td align='center'>{Moment(item.createAt.toDate()).format("DD/MM/YYYY")}</td>
                                                {
                                                    this.state.isAdministrador &&
                                                    <td align='right'>
                                                        <div className='btn-group'>
                                                            <button type="button" onClick={() => { this.removePaymentChange(index) }} className="btn btn-danger">
                                                                <i className="fas fa-trash" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className="m-3">
                            <button onClick={(e) => this.setState({ showModalHistoryChange: false })} className='btn btn-secondary'>Fechar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog onClose={(e) => this.setState({ showFutureCharges: false })} maxWidth={'md'} fullWidth open={this.state.showFutureCharges}>
                    <MuiDialogTitle className='text-center'>
                        Histórico cobranças
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className='table-responsive'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td><strong>Usuário</strong></td>
                                        <td><strong>Data</strong></td>
                                        <td><strong>Valor total</strong></td>
                                        <td width={10}><strong>Ticket</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.chageSelected?.spuns?.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item?.createdBy?.nome ?? "-"}</td>
                                                <td>{Moment(item.createAt.toDate()).format("DD/MM/YYYY [às] HH:mm:ss A")}</td>
                                                <td><NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.ticket?.paymentsLogged.map(e => e.resumo?.valorCobrado ?? 0).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></td>
                                                <td>
                                                    <Tooltip title="Visualizar ticket" placement="top">
                                                        <a href={`/buscar/ticket?id=${item.ticket.id}`} target="_blank" className="btn btn-primary">
                                                            <i className="fas fa-eye" />
                                                        </a>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className="m-3">
                            <button onClick={(e) => this.setState({ showFutureCharges: false })} className='btn btn-secondary'>Fechar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog onClose={this.handleCloseRegisterPayment} maxWidth={'sm'} open={this.state.showModalToRegisterPayment}>
                    {
                        this.state.state === "loading" && <Loading />
                    }
                    {
                        this.state.state === "normal" &&
                        <>

                            <MuiDialogTitle className='text-center'>
                                Pagamento da mensalidade
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className="col-lg-12">
                                    <div className="alert alert-secondary">
                                        <strong>Usuário que esta fazendo o pagamento</strong> <br />
                                        <strong>Nome: </strong><span>{getUsuario()?.nome}</span> <br />
                                        <strong>Data e hora: </strong><span>{Moment(new Date()).format("DD/MM/YYYY [às] HH:mm A")}</span> <br />
                                    </div>
                                    <CashierSelector title="Fazer lançamento no caixa:" onSelectedCashier={this.onSelectedCashier} />
                                </div>
                                {
                                    this.state.plano &&
                                    <div className='col-lg-12'>
                                        <div className="col-lg-12">
                                            <strong>Plano: </strong> <br />
                                            {this.state.plano.nome}, das {this.state.plano.horarioInicio} as {this.state.plano.horarioFim}
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-12">
                                                <strong>Vencimento atual do mensalista: </strong> <br />
                                                {Moment(this.state.plano.dataFatura.seconds * 1000).format("DD/MM/YYYY")}
                                            </div>
                                            {/* <div className="col-lg-12">
                                                <strong>Novo vencimento: </strong> <br />
                                                {Moment(this.state.novoVencimento.seconds * 1000).format("DD/MM/YYYY")}
                                                <div>
                                                    <MobileDatePicker
                                                        label="-"
                                                        inputFormat="DD/MM/yyyy"
                                                        value={this.state.novoVencimento}
                                                        onChange={this.handleNovoVencimentoDateChange}
                                                        renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-lg-12">
                                                <strong>Data do pagamento: </strong> <br />
                                                <div>
                                                    <MobileDatePicker
                                                        label="-"
                                                        inputFormat="DD/MM/yyyy"
                                                        value={this.state.dataBaixa}
                                                        onChange={this.handleBaixaDateChange}
                                                        renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-6">
                                                <strong>Valor do pagamento: </strong>
                                                <CurrencyInput value={this.state.valorPlano && this.state.valorPlano} prefix="R$" className='form-control' onChangeEvent={this.updateValorPlano} />
                                            </div>
                                            <div className="col-lg-6">
                                                <strong>Forma de pagamento: </strong>
                                                <select className='form-select mb-3' value={this.state.formaDePagamentoSelecionada?.id} onChange={(e) => this.setState({ formaDePagamentoSelecionada: e.target.value })}>
                                                    <option value=''>Selecione</option>
                                                    {
                                                        this.state.formasPagamentos?.map(item => { return <option value={item.id}>{item.nome === item.tipo ? item.nome : `${item.tipo} - ${item.nome}`}</option> })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <NewNoteWidget
                                            screen="Detalhes mensalista"
                                            document={this.state.documento}
                                            name={this.state.nome}
                                            onChangeValue={this.onChangeNewNoteForm} />
                                    </div>
                                }
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className="btn-group m-3">
                                    <button onClick={this.handleCloseRegisterPayment} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={this.confirmMonthlyPayment} className='btn btn-success'>Confirmar pagamento</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                    {
                        this.state.state === "receipt" &&
                        <>

                            <MuiDialogTitle className='text-center'>
                                Pagamento da mensalidade
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='col-lg-12 text-center'>
                                    <QRCodeSVG value={this.state.urlCharge} size={250} />
                                    <h4 className='text-success mt-3'>
                                        <span>Cobrança paga com sucesso!</span>
                                    </h4>
                                    <h6>O cliente pode escanear o QRCode acima para visualizar o comprovante.</h6>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className="btn-group m-3">
                                    <button onClick={this.handleCloseQRCode} className='btn btn-secondary'>Fechar</button>
                                    <>
                                        <div style={{ display: "none" }}>
                                            <PrinterMonthlyChargeComponent
                                                charge={this.state.chageSelected}
                                                park={getEstacionamento()}
                                                ref={el => (this.componentRef = el)} />
                                        </div>
                                        <ReactToPrint
                                            trigger={() => {
                                                return <button className='btn btn-secondary'>Imprimir</button>
                                            }}
                                            content={() => this.componentRef}
                                        />
                                    </>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>
                <Dialog onClose={this.handleEditChange} maxWidth={'xs'} fullWidth open={this.state.showModalEditChange}>
                    <MuiDialogTitle className='text-center'>
                        Editar cobrança
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className='row'>
                            {
                                this.state.chageSelected?.status === "payed" &&
                                <div className="col-lg-12">
                                    <CashierSelector title="Caixa do lançamento:" selectedId={this.state.selectedId} onSelectedCashier={this.onSelectedCashier} />
                                </div>
                            }
                            <div className="col-lg-12">
                                <strong>Vencimento:</strong> <br />
                                <div>
                                    <MobileDatePicker
                                        label="-"
                                        inputFormat="DD/MM/yyyy"
                                        value={this.state.changeDateSelected}
                                        onChange={this.handleChangeDateSelected}
                                        renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <strong>Valor cobrança: </strong>
                                <CurrencyInput value={this.state.netValueChage && this.state.netValueChage} prefix="R$" className='form-control' onChangeEvent={this.updateChangeNetValue} />
                            </div>
                            {
                                this.state.isAdministrador && this.state.chageSelected?.status === "payed" &&
                                <div>
                                    <strong>Valor pago: </strong>
                                    <CurrencyInput value={this.state.valorPlano && this.state.valorPlano} prefix="R$" className='form-control' onChangeEvent={this.updateValorPlano} />
                                </div>
                            }
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className="btn-group m-3">
                            <button onClick={this.saveChange} className='btn btn-success'>Salvar alterações</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

class ListarVeiculos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isLoading: true,
            clientId: getCliente()?.id,
            estacionamentoId: getEstacionamento()?.id,
            placa: '',
            modelo: '',
            marca: '',
            ano: '',
            cor: '',
            cartao: "",
            items: [],
            itemsAux: [],
            tickets: [],
            advancedSearchModelList: [],
            advancedSearchModelOptions: [],
            showSearchField: false,
            random: Math.random()
        }
    }

    componentDidMount = () => {
        this.load()
    }

    reset = () => {
        this.setState({ id: '' })
        this.setState({ placa: '' })
        this.setState({ modelo: '' })
        this.setState({ marca: '' })
        this.setState({ ano: '' })
        this.setState({ cor: '' })
        this.setState({ cartao: '' })
    }

    onEdit = (item) => {
        this.setState({ id: item.id })
        this.setState({ placa: item.placa })
        this.setState({ modelo: item.modelo })
        this.setState({ marca: item.marca })
        this.setState({ ano: item.ano })
        this.setState({ cor: item.cor })
        this.setState({ cartao: item.cartao })
    }

    load = async () => {
        this.setState({ isLoading: true })
        const items = await getVehiclesByMonthlyIdAndClientId({ monthlyId: this.props.clienteId, clienteId: this.state.clientId })
        if (items.length > 5) {
            this.setState({ itemsAux: items })
            this.setState({ showSearchField: true })
        }
        this.setState({ items: items })
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        if (parameters.habilitarCartaoParaAcesso) {
            await this.loadAccessCardsIfNeed()
            this.setState({ habilitarCartaoParaAcesso: true })
        }
        this.setState({ isLoading: false })
    }

    loadAccessCardsIfNeed = async () => {
        const allAccessCards = await getAllAccessCardsByReferenceId({ referenceId: this.props.clienteId, estacionamentoId: this.state.estacionamentoId })
        this.setState({ allAccessCards: allAccessCards })
    }

    checkFields = () => {
        const allFilled = (this.state.placa !== '' && this.state.modelo !== '' && this.state.marca !== '')
        if (!allFilled) {
            toastWarning("Informe todos os campos obrigatórios.")
            return false
        }
        const isPlate = isValidatePlate(this.state.placa)
        if (!isPlate) {
            toastWarning("Informe uma placa válida.")
            return false
        }
        return true
    }

    onRemove = async (item) => {
        const items = await getVehiclesByMonthlyIdAndClientId({ monthlyId: this.props.clienteId, clienteId: this.state.clientId })
        if (items.length > 1) {
            var confirm = window.confirm(`Você tem certeza que deseja remover o veículo com a placa: ${item.placa.toUpperCase()}?`);
            if (confirm) {
                await deleteVehicleById({ id: item.id })
                this.load()
                sendLogByUser("Detalhes Mensalistas", `Removeu o veículo ${item.placa} do mensalista ${this.props.clienteNome}`, item)
            }
        } else {
            toastWarning("Você não pode excluir o último veículo.")
        }
    }

    update = async () => {
        if (!this.checkFields()) {
            return
        }
        toastLoading("Atualizando veículo...")
        const data = {
            estacionamentoId: this.state.estacionamentoId,
            placa: this.state.placa.replaceAll("-", "").toUpperCase(),
            modelo: this.state.modelo,
            marca: this.state.marca,
            ano: this.state.ano,
            tipo: this.state.tipo,
            cor: this.state.cor,
            cartao: String(this.state.cartao),
            alterAt: new Date(),
            alterBy: getUsuario().email
        }
        await updateVehicleById({ id: this.state.id, data: data })
        toastSuccess("Veículo atualizado com sucesso!")
        sendClickButton("Detalhes Mensalistas - Veículo", "Atualizar", true, data)
        toastDismissLoading()
        this.reset()
        this.load()
    }

    add = async () => {
        if (!this.checkFields()) {
            return
        }
        const vehicle = await getVehicleByPlateAndClientId({ clientId: this.state.clientId, placa: this.state.placa })
        if (!isNullOrEmpty(vehicle)) {
            toastWarning('Já existe um veiculo com esta placa cadastrado para esta matriz.')
            return
        }
        toastLoading("Cadastrando veículo...")
        const data = {
            clientId: this.state.clientId,
            mensalistaId: this.props.clienteId,
            placa: this.state.placa,
            modelo: this.state.modelo,
            marca: this.state.marca,
            tipo: this.state.tipo,
            cor: this.state.cor,
            cartao: this.state.cartao,
            createdAt: new Date(),
            createdBy: getUsuario().email
        }
        await addVehicle(data)
        sendClickButton("Detalhes Mensalistas - Veículo", "Cadastrar", true, data)
        toastDismissLoading()
        this.reset()
        this.load()
    }

    showAccessHistory = async (item) => {
        toastLoading("Buscando histórico de acessos...")
        const tickets = await ticketsByPlatesAndClientId({ placas: [item.placa.replaceAll("-", "").toUpperCase()] })
        toastDismissLoading()
        this.setState({ tickets: tickets })
        this.setState({ showModalAccessHistory: true })
    }

    onCloseAnalyticsDialog = () => {
        this.setState({ showModalAccessHistory: false })
    }

    onModelAdvancedSearchFocus = async (e) => {
        const models = await getAllBranchesAndModels()
        const list = models.map(e => { return { title: e.value } })
        this.setState({ advancedSearchModelOptions: list })
        this.setState({ advancedSearchModelList: models })
    }

    advancedSearchModel = (e, newValue) => {
        if (newValue?.title) {
            const model = this.state.advancedSearchModelList.filter(e => e.value.toLowerCase().includes(newValue.title.toLowerCase()))
            const modelo = newValue?.title.substring(newValue?.title.indexOf(' ') + 1);
            const marca = newValue.title.split(" ", 1)[0]
            if (modelo != marca) {
                this.setState({ marca: marca, modelo: modelo, tipo: model.type })
            } else {
                this.setState({ marca: marca, modelo: "Outros", tipo: model.type })
            }
        } else {
            this.setState({ marca: null, modelo: null, tipo: null })
        }
    }

    searchField = (e) => {
        const value = normalizedField(e.target.value)?.toLowerCase()
        this.setState({ search: e.target.value })
        const result = this.state.itemsAux.filter(e =>
            normalizedField(e.placa?.toLowerCase()).includes(value) ||
            normalizedField(e.modelo?.toLowerCase()).includes(value) ||
            normalizedField(e.ano?.toLowerCase()).includes(value) ||
            normalizedField(e.tipo?.toLowerCase()).includes(value) ||
            normalizedField(e.cor?.toLowerCase()).includes(value) ||
            normalizedField(e.cartao?.toLowerCase()).includes(value)
        )
        this.setState({ items: result.sort((a, b) => -b.placa?.localeCompare(a.placa)) })
    }

    render() {
        return (
            <>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Cadastrar veículos
                    </div>
                    <div className='card-body'>
                        <AlertWarning message="A placa informada será utilizada para encontrar o mensalista no sistema." />
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className="col-lg-3">
                                    <label>Placa (*)</label>
                                    <input type="text" maxLength='7' onChange={(e) => this.setState({ placa: e.target.value })} value={this.state.placa.toUpperCase()} className="form-control" />
                                </div>
                                <div className="col-lg-3">
                                    <label>Marca e modelo</label>
                                    <Autocomplete
                                        id="asynchronous-demo"
                                        open={this.props.isOpen}
                                        onOpen={(e) => this.setState({ isOpen: true })}
                                        onClose={(e) => this.setState({ isOpen: false })}
                                        onFocus={this.onModelAdvancedSearchFocus}
                                        onChange={this.advancedSearchModel}
                                        isOptionEqualToValue={(option, value) => option.title === value.title}
                                        getOptionLabel={(option) => option.title}
                                        options={this.state.advancedSearchModelOptions}
                                        loading={this.state.advancedSearchModelIsLoading}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small" className='btn-block'
                                                label="-"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {this.state.advancedSearchModelIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />

                                </div>
                                <div className="col-lg-3">
                                    <label>Cor</label>
                                    <select className='form-select' onChange={(e) => this.setState({ cor: e.target.value })} value={this.state.cor}>
                                        <option key="" value=''>Selecione</option>
                                        <option key="Branco" value='Branco'>Branco</option>
                                        <option key="Prata" value='Prata'>Prata</option>
                                        <option key="Preto" value='Preto'>Preto</option>
                                        <option key="Cinza" value='Cinza'>Cinza</option>
                                        <option key="Vermelho" value='Vermelho'>Vermelho</option>
                                        <option key="Azul" value='Azul'>Azul</option>
                                        <option key="Amarelo" value='Amarelo'>Amarelo</option>
                                        <option key="Verde" value='Verde'>Verde</option>
                                        <option key="Vinho" value='Vinho'>Vinho</option>
                                        <option key="Marrom" value='Marrom'>Marrom</option>
                                        <option key="Bege" value='Bege'>Bege</option>
                                        <option key="Laranja" value='Laranja'>Laranja</option>
                                        <option key="Outros" value='Outros'>Outros</option>
                                    </select>
                                </div>
                                {
                                    this.state.habilitarCartaoParaAcesso &&
                                    <div className="col-lg-3">
                                        <label>Associar cartão</label>
                                        <select className='form-select' onChange={(e) => this.setState({ cartao: e.target.value })} value={this.state.cartao}>
                                            <option value="">Selecionar</option>
                                            {
                                                this.state.allAccessCards?.map(item => { return <option key={item.id} value={item.cardNumber}>{item.cardNumber}</option> })
                                            }
                                        </select>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <div className="btn-group">
                            {
                                this.state.id && <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                            }
                            <button type="button" onClick={(e) => { this.state.id ? this.update() : this.add() }} className="btn btn-primary">{this.state.id ? 'Atualizar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <div className="card my-3">
                    <div className='card-body'>
                        {
                            this.state.showSearchField &&
                            <div className='row'>
                                <div className="col-lg-12">
                                    <input type="text" placeholder='Pesquisar por placa, marca, modelo ou cor...' onChange={this.searchField} value={this.state.search} className="form-control" />
                                </div>
                            </div>
                        }
                        {
                            this.state.items.length === 0 && !this.state.isLoading ?
                                <EmptyContent text='Nenhum veículo adicionado.' />
                                :
                                !this.state.isLoading ?
                                    <div className='table-responsive'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <td><strong>Placa</strong></td>
                                                    <td align='center'><strong>Modelo</strong></td>
                                                    <td align='center' className="d-none d-sm-table-cell"><strong>Marca</strong></td>
                                                    <td align='center' className="d-none d-sm-table-cell"><strong>Cor</strong></td>
                                                    {
                                                        this.state.habilitarCartaoParaAcesso &&
                                                        <td align='center' className="d-none d-sm-table-cell"><strong>Cartão</strong></td>
                                                    }
                                                    <td width="80"></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.items.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <Tooltip title={Moment(item.createdAt.seconds * 1000).format(`[${item.id} - ${item.estacionamentoId} - Veículo cadastrado em] DD/MM/YYYY [às] HH:mm [por ${item.createdBy}]`)} placement="top">
                                                                    <span>{item.placa}</span>
                                                                </Tooltip>
                                                            </td>
                                                            <td align='center' className="d-none d-sm-table-cell">{takeIfIsNotNullOrEmpty(item.modelo) ?? '-'}</td>
                                                            <td align='center' className="d-none d-sm-table-cell">{takeIfIsNotNullOrEmpty(item.marca) ?? '-'}</td>
                                                            <td align='center' className="d-none d-sm-table-cell">{takeIfIsNotNullOrEmpty(item.cor) ?? '-'}</td>
                                                            {
                                                                this.state.habilitarCartaoParaAcesso &&
                                                                <td align='center' className="d-none d-sm-table-cell">{takeIfIsNotNullOrEmpty(item.cartao) ?? "Não associado"}</td>
                                                            }
                                                            <td>
                                                                <div className="btn-group">
                                                                    <Tooltip title="Histórico de acessos" placement="top">
                                                                        <button className='btn btn-warning text-white' onClick={(e) => this.showAccessHistory(item)}>
                                                                            <QueryStatsIcon />
                                                                        </button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Editar veículo" placement="top">
                                                                        <button type="button" onClick={() => { this.onEdit(item) }} className="btn btn-secondary">
                                                                            <i className="fas fa-edit" />
                                                                        </button>
                                                                    </Tooltip>
                                                                    <UserPermissionButton
                                                                        className="btn btn-danger"
                                                                        action={() => { this.onRemove(item) }}
                                                                        permission="MONTHLY_REMOVE_VEHICLE">
                                                                        <Tooltip title="Apagar veículo" placement="top">
                                                                            <i className="fas fa-trash" />
                                                                        </Tooltip>
                                                                    </UserPermissionButton>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            this.state.showModalAccessHistory && <TicketAnalyticsDialog tickets={this.state.tickets} onClose={this.onCloseAnalyticsDialog} />
                                        }
                                    </div>
                                    :
                                    <Loading />
                        }
                    </div>
                </div>
            </>
        )
    }
}

export { DetalhesMensalista, ListarVeiculos, MonthlyChages };